<template>
    <div class="music-wrap">
        <!-- 曲谱分类 -->
        <MusicType></MusicType>
        <div class="content__wrap">
            <div class="left__wrap">
                <!-- 热门专辑 -->
                <div class="item__wrap">
                    <PlateHeader title="热门专辑Top5" :showMore="false"></PlateHeader>
                    <div class="hot__item" v-for="(item, index) in hotAlbums" :key="index">
                        <i class="hot__ranking">{{ index + 1 }}</i>
                        <img class="hot__img" :src="item.cover" alt="" />
                        <span class="hot__name">{{ item.title }}</span>
                        <span class="hot__num">曲谱：{{ item.num }}</span>
                    </div>
                </div>
                <!-- 推荐曲谱 -->
                <div class="item__wrap">
                    <PlateHeader title="推荐曲谱" :showMore="false"></PlateHeader>
                    <div class="music__item" v-for="(item, index) in 5" :key="index" @click="handleMusicDetail(item)">
                        <span class="music__type">原创曲谱</span>
                        <span class="music__name">三门峡畅想曲（钢琴独奏曲</span>
                    </div>
                </div>
                <!-- 热门曲谱 -->
                <div class="item__wrap">
                    <PlateHeader title="热门曲谱" :showMore="false"></PlateHeader>
                    <div class="music__item" v-for="(item, index) in 5" :key="index">
                        <span class="music__type">原创曲谱</span>
                        <span class="music__name">三门峡畅想曲（钢琴独奏曲</span>
                    </div>
                </div>
            </div>
            <div class="right__wrap">
                <el-carousel :interval="5000" arrow="always">
                    <el-carousel-item v-for="item in 4" :key="item">
                        <h3>{{ item }}</h3>
                    </el-carousel-item>
                </el-carousel>
                <!-- 专辑分类 -->
                <div class="album__wrap">
                    <h2 class="album__title">热门推荐</h2>
                    <span class="album__item" v-for="(item, index) in albumList" :key="index">{{ item }}</span>
                </div>
                <!-- 专辑列表 -->
                <div class="album__list">
                    <div class="album__item" v-for="(item, index) in 10" :key="index">
                        <img class="album__picture" src="https://dss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=1254600355,3481167582&fm=26&gp=0.jpg" alt="" />
                        <div class="album__info">
                            <span class="album__name">大学小提琴弹奏专辑</span>
                            <span class="album__num">曲谱：30</span>
                            <span class="album__introduce">在六线谱中会有一些数字符号 阿拉伯数字等等 比如最上面那个1 左就...</span>
                        </div>
                    </div>
                </div>
                <!-- 分页 -->
                <el-pagination background layout="prev, pager, next" :total="total" :current-page="pageNumber" @current-change="handlePageChange"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import MusicType from '@/components/MusicType';
import PlateHeader from '@/components/PlateHeader';
import { musics, hotAlbum } from '@/api/music';
export default {
    components: {
        MusicType,
        PlateHeader
    },
    data() {
        return {
            searchTitle: '', // 搜索名
            musicTypeId: '', // 曲谱分类ID
            musics: '', // 曲谱列表
            hotAlbums: [], // 热门专辑列表
            albumList: ['最新曲谱', '原创曲谱', '最热曲谱', '最新曲谱'], // 热门推荐,
            pageSize: 10,
            pageNumber: 1,
            total: 0
        };
    },
    watch: {
        $route(val) {
            this.musicTypeId = val.params.typeid;
        }
    },
    created() {
        this.getMusics();
        this.getHotAlbum();
    },
    methods: {
        // 获取曲谱分类下的列表
        getMusics() {
            musics({
                title: this.searchTitle,
                categoryList: this.$route.params.typeid == '-1' ? '' : [this.$route.params.typeid],
                pageSize: this.pageSize,
                pageNumber: this.pageNumber
            }).then(res => {
                this.total = Number(res.data.total);
                this.musics = res.data.list;
            });
        },
        // 分页
        handlePageChange(page) {
            console.log(page);
        },
        // 查看曲谱详情
        handleMusicDetail(item) {
            console.log(item);
            this.$router.push({ name: 'musicDetail', query: { id: 11111 } });
        },
        // 获取热门专辑
        getHotAlbum() {
            hotAlbum().then(res => {
                this.hotAlbums = res.data.data;
            });
        }
    }
};
</script>

<style scoped lang="scss">
.music-wrap {
    width: 1200px;
    margin: 0 auto 20px auto;
    .content__wrap {
        display: flex;
        .left__wrap {
            width: 380px;
            margin-right: 20px;
            display: flex;
            flex-direction: column;
            .item__wrap {
                padding: 30px;
                margin-bottom: 20px;
                border: 1px solid #d7dce5;
                .hot__item {
                    margin-top: 30px;
                    display: flex;
                    align-items: center;
                    .hot__ranking {
                        width: 24px;
                        height: 26px;
                        color: #ffffff;
                        font-size: 20px;
                        font-style: normal;
                        border-radius: 4px;
                        padding-bottom: 5px;
                        position: relative;
                        display: flex;
                        flex-shrink: 0;
                        align-items: center;
                        justify-content: center;
                    }
                    .hot__img {
                        width: 60px;
                        height: 60px;
                        margin: 0 10px;
                        border-radius: 12px;
                        flex-shrink: 0;
                    }
                    .hot__name {
                        color: #2f3033;
                        font-size: 16px;
                        font-weight: bold;
                        margin-right: 10px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .hot__num {
                        color: #8f9299;
                        flex-shrink: 0;
                        font-size: 16px;
                        margin-left: auto;
                    }
                    &:nth-child(2) .hot__ranking {
                        background-color: #ff8116;
                    }
                    &:nth-child(3) .hot__ranking {
                        background-color: #ff4184;
                    }
                    &:nth-child(4) .hot__ranking {
                        background-color: #4196ff;
                    }
                    &:nth-of-type(n + 5) .hot__ranking {
                        background-color: #c0c4cc;
                    }
                    .hot__ranking:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 0;
                        height: 0;
                        border-left: 11px solid transparent;
                        border-right: 11px solid transparent;
                        border-bottom: 4px solid #ffffff;
                    }
                }
                .music__item {
                    cursor: pointer;
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    .music__type {
                        color: #4195ff;
                        flex-shrink: 0;
                        font-size: 16px;
                        margin-right: 10px;
                    }
                    .music__name {
                        color: #2f3033;
                        font-size: 16px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
                .music__item:hover {
                    .music__name {
                        color: #4195ff;
                    }
                }
            }
        }
        .right__wrap {
            width: calc(100% - 400px);
            display: flex;
            flex-direction: column;
            ::v-deep .el-carousel__container {
                height: 210px;
            }
            .el-carousel__item h3 {
                margin: 0;
                opacity: 0.75;
                color: #475669;
                font-size: 18px;
                line-height: 210px;
                text-align: center;
                background-color: #4196ff;
            }
            .album__wrap {
                margin-top: 60px;
                display: flex;
                align-items: center;
                .album__title {
                    flex-shrink: 0;
                    font-size: 30px;
                    font-weight: bold;
                    margin: 0 40px 0 0;
                }
                .album__item {
                    width: 120px;
                    height: 44px;
                    color: #606266;
                    font-size: 18px;
                    border-radius: 12px;
                    background-color: #efefef;
                    transition: all 0.3s;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .album__item:not(:last-child) {
                    margin-right: 20px;
                }
                .album__item:hover {
                    color: #ffffff;
                    background-color: #4196ff;
                }
                .album__item.recommend__item--active {
                    color: #ffffff;
                    background-color: #4196ff;
                }
            }
            .album__list {
                margin-bottom: 100px;
                display: flex;
                flex-wrap: wrap;
                .album__item {
                    width: 50%;
                    margin-top: 40px;
                    display: flex;
                    justify-content: space-between;
                    .album__picture {
                        width: 180px;
                        height: 180px;
                        margin-right: 20px;
                        border-radius: 12px;
                    }
                    .album__info {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        .album__name {
                            color: #2f3033;
                            font-size: 20px;
                            font-weight: bold;
                        }
                        .album__num {
                            color: #5f6166;
                            font-size: 18px;
                            padding: 5px 15px;
                            border-radius: 4px;
                            margin: 20px auto 30px 0;
                            background-color: #eff5f7;
                        }
                        .album__introduce {
                            color: #8f9299;
                            font-size: 16px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
            ::v-deep .el-pagination {
                text-align: center;
            }
        }
    }
}
</style>
