<template>
    <div class="footer-wrap" :style="{ paddingBottom: paddingBottom + 'px' }">
        <div class="footer__content">
            <div class="footer__logo"></div>
            <div class="problem__wrap">
                <div class="problem__header">
                    <i class="problem__icon"></i>
                    <span class="label">实用话题</span>
                </div>
                <div class="problem__content">
                    <router-link class="problem__item" to="/home/index">网站违规广告细则说明</router-link>
                    <router-link class="problem__item" to="/home/index">申请网站歌单推荐</router-link>
                    <router-link class="problem__item" to="/home/index">打开移动端版本</router-link>
                    <router-link class="problem__item" to="/home/index">申请网站推荐歌曲</router-link>
                    <router-link class="problem__item" to="/home/agreement">平台用户协议</router-link>
                </div>
            </div>
            <div class="other__wrap">
                <div class="entry__wrap">
                    <router-link class="content-item" to="/home/index">版权指引</router-link>
                    <router-link class="content-item" to="/home/index">隐私政策</router-link>
                    <router-link class="content-item" to="/home/index">商务合作</router-link>
                    <router-link class="content-item" to="/home/index">联系我们</router-link>
                    <router-link class="content-item" to="/home/index">授权协议</router-link>
                    <router-link class="content-item" to="/home/index">关于我们</router-link>
                    <router-link class="content-item" to="/home/index">诚聘英才</router-link>
                    <router-link class="content-item" to="/home/index">帮助中心</router-link>
                    <router-link class="content-item" to="/home/index">平台认证作曲人协议</router-link>
                </div>
                <div class="putonrecord">沪ICP备06019413号 | 沪ICP证B2-20080088 | 沪公网安备31011202007947号</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            paddingBottom: 60
        };
    },
    watch: {
        // 监听路由变化 曲谱详情底部需要高60px
        $route: {
            deep: true, // 深入观察,监听数组值，对象属性值的变化
            immediate: true, // 首次加载的时候执行函数
            handler: function(val) {
                let controllers = ['musicDetail'];
                if (controllers.includes(val.name)) {
                    this.paddingBottom = 120;
                } else {
                    this.paddingBottom = 60;
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
.footer-wrap {
    width: 100%;
    padding: 60px 0;
    background-color: #2f3033;
    display: flex;
    align-items: center;
    justify-content: center;
    .footer__content {
        width: 1200px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        .footer__logo {
            width: 200px;
            height: 55px;
            flex-shrink: 0;
            cursor: pointer;
            display: inline-block;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-image: url(../../assets/image/footer-logo.png);
        }
        .problem__wrap {
            margin-left: 84px;
            margin-right: 120px;
            display: flex;
            flex-shrink: 0;
            flex-direction: column;
            .problem__header {
                margin-bottom: 30px;
                display: flex;
                align-items: center;
                .problem__icon {
                    width: 26px;
                    height: 26px;
                    margin-right: 20px;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-image: url(../../assets/image/footer-icon.png);
                }
                .label {
                    color: #ffffff;
                    font-size: 16px;
                }
            }
            .problem__content {
                margin-right: 46px;
                display: flex;
                flex-direction: column;
                .problem__item {
                    opacity: 0.3;
                    color: #ffffff;
                    cursor: pointer;
                    font-size: 14px;
                    line-height: 30px;
                    text-decoration: none;
                }
                .problem__item:hover {
                    opacity: 1;
                }
            }
        }
        .other__wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .entry__wrap {
                display: flex;
                flex-wrap: wrap;
                a {
                    opacity: 0.6;
                    color: #ffffff;
                    cursor: pointer;
                    font-size: 14px;
                    margin-bottom: 30px;
                    margin-right: 128px;
                    text-decoration: none;
                }
                a:nth-child(4n) {
                    margin-right: 0;
                }
                a:hover {
                    opacity: 1;
                }
            }
            .putonrecord {
                opacity: 0.3;
                color: #ffffff;
                font-size: 14px;
            }
        }
    }
}
</style>
