// 工具方法集合
import state from './state.js'; // 设置,获取Vux数据
import test from './test.js'; // 校验方法
import throttle from './throttle.js'; // 校验方法

export default {
    ...state,
    ...throttle,
    test
};
