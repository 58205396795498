<template>
    <div class="header-wrap">
        <!-- 未登录 -->
        <div class="header--not" v-if="userInfo">
            <div class="header__logo"></div>
            <div class="header__search">
                <el-dropdown trigger="click" @command="handleCommand">
                    <div class="el-dropdown-link">
                        <span class="select-title">{{ selectTitle }}</span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(item, index) in list" :key="index" :command="index">{{ item.title }}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <input class="search-input" type="text" v-model="searchValue" placeholder="请输入搜索内容" />
                <i class="el-icon-search"></i>
            </div>
            <div class="header__button">
                <div class="button left">
                    <span class="hover">登录</span>
                    <span>/</span>
                    <span class="hover">注册</span>
                </div>
                <div class="button right">
                    <i class="upload-icon"></i>
                    <span>上传曲谱</span>
                </div>
            </div>
        </div>
        <!-- 已登录 -->
        <div class="header--already" v-if="!userInfo">
            <div class="header__logo"></div>
            <div class="header__button">
                <div class="button left">
                    <i class="music-icon"></i>
                    <span>去打谱</span>
                </div>
                <div class="button right">
                    <i class="upload-icon"></i>
                    <span>上传曲谱</span>
                </div>
            </div>
            <div class="user__info">
                <i class="search__button"></i>
                <i class="message__button"></i>
                <el-popover placement="top" width="150" trigger="click" popper-class="user__operation__wrap">
                    <img class="user__img" slot="reference" src="https://img2.yuntouxiang.com/file/20170124/20d8c54b8843a37c240fd918a1019bd8.jpg" alt="" />
                    <div class="user__operation">
                        <div @click="handleLogOut">退出登录</div>
                    </div>
                </el-popover>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { logout } from '@/api/user';
import { removeToken } from '@/utils/auth';
export default {
    name: 'Header',
    data() {
        return {
            selectTitle: '曲谱',
            selectIndex: 0,
            list: [{ title: '曲谱' }, { title: '乐谱' }],
            searchValue: ''
        };
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        // 选择谱子类型
        handleCommand(command) {
            this.selectTitle = this.list[command].title;
        },
        // 退出登录
        handleLogOut() {
            console.log(11);
            logout().then(() => {
                removeToken();
                this.$setState('token', null);
                this.$message({
                    message: '您已退出登录',
                    type: 'success'
                });
            });
        }
    }
};
</script>

<style scoped lang="scss">
.header-wrap {
    width: 1200px;
    margin: 40px auto;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    .header__logo {
        width: 200px;
        height: 55px;
        flex-shrink: 0;
        display: inline-block;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../assets/image/logo.png);
    }
    .header--not {
        flex: 1;
        display: flex;
        align-items: center;
        .header__search {
            width: 275px;
            height: 40px;
            padding: 0 20px;
            margin-left: 40px;
            margin-right: auto;
            border-radius: 12px;
            border: 1px solid #d8dce6;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .select-title {
                color: #909399;
            }
            .search-input {
                border: none;
                font-size: 14px;
                max-width: 120px;
            }
            .el-icon-search {
                color: #4196ff;
                font-size: 18px;
                font-weight: bold;
            }
        }
        .header__button {
            width: 340px;
            height: 46px;
            position: relative;
            .button {
                width: 170px;
                height: 46px;
                color: #ffffff;
                font-size: 18px;
                padding: 0 20px;
                position: absolute;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                display: flex;
                align-items: center;
                .hover {
                    cursor: pointer;
                }
                .upload-icon {
                    margin-left: 10px;
                    width: 24px;
                    height: 22px;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-image: url(../../assets/icon/upload.png);
                }
                span {
                    color: #ffffff;
                }
            }
            .button.left {
                left: 0;
                justify-content: center;
                background-image: url(../../assets/image/left-button.png);
            }
            .button.right {
                right: 0;
                justify-content: space-between;
                background-image: url(../../assets/image/right-button.png);
            }
        }
    }
    .header--already {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .header__button {
            width: 340px;
            height: 46px;
            position: relative;
            .button {
                width: 170px;
                height: 46px;
                color: #ffffff;
                font-size: 18px;
                padding: 0 20px;
                position: absolute;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                display: flex;
                align-items: center;
                .hover {
                    cursor: pointer;
                }
                .music-icon {
                    margin-right: 20px;
                    width: 25px;
                    height: 22px;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-image: url(../../assets/icon/music.png);
                }
                .upload-icon {
                    margin-left: 10px;
                    width: 24px;
                    height: 22px;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-image: url(../../assets/icon/upload.png);
                }
                span {
                    color: #ffffff;
                }
            }
            .button.left {
                left: 0;
                justify-content: center;
                background-image: url(../../assets/image/left-button.png);
            }
            .button.right {
                right: 0;
                justify-content: space-between;
                background-image: url(../../assets/image/right-button.png);
            }
        }
        .user__info {
            width: 200px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .search__button {
                cursor: pointer;
                width: 30px;
                height: 30px;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-image: url(../../assets/icon/search.png);
            }
            .message__button {
                cursor: pointer;
                width: 32px;
                height: 34px;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-image: url(../../assets/icon/message.png);
            }
            .user__img {
                cursor: pointer;
                width: 60px;
                height: 60px;
                border-radius: 100%;
            }
        }
    }
}
</style>
