export function music() {
    return {
        title: 'sting', //标题
        rightText: '', //右边文本
        content: [
            //乐曲内容
            {
                //小节
                track: [
                    //音轨
                    {
                        type: 'NUMBERED', //类型：EMPTY占位轨、NUMBERED简谱、STAVE五线谱、LYRIC歌词
                        note: [
                            {
                                part: [
                                    //多声部
                                    {
                                        type: 'CHAR'
                                    },
                                    {
                                        pitch: 5, //音高，-1为占位符,-2延音符
                                        pitchLevel: 0, //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                        pitchTime: 0.5 //时值
                                    }
                                ]
                            },
                            {
                                part: [
                                    //多声部
                                    {
                                        type: 'EMPTY'
                                    },
                                    {
                                        type: 'EMPTY'
                                    }
                                ]
                            },
                            {
                                part: [
                                    //多声部
                                    {
                                        pitch: 5, //音高，-1为占位符,-2延音符
                                        pitchLevel: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                    },
                                    {
                                        pitch: 5, //音高，-1为占位符,-2延音符
                                        pitchLevel: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                    }
                                ]
                            },
                            {
                                align: 'string', //对齐方式，CENTER,TOP,BOTTOM，默认CENTER
                                part: [
                                    //多声部
                                    {
                                        pitch: 6, //音高，-1为占位符,-2延音符
                                        pitchLevel: 0, //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                        pitchTime: 0.25 //时值
                                    },
                                    {
                                        pitch: 6, //音高，-1为占位符,-2延音符
                                        pitchLevel: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        type: 'NUMBERED', //类型：EMPTY占位轨、NUMBERED简谱、STAVE五线谱、LYRIC歌词
                        note: [
                            {
                                part: [
                                    //多声部
                                    {
                                        pitch: 5, //音高，-1为占位符,-2延音符
                                        pitchLevel: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                    }
                                ]
                            },
                            {
                                part: [
                                    //多声部
                                    {
                                        type: 'EMPTY'
                                    }
                                ]
                            },
                            {
                                part: [
                                    //多声部
                                    {
                                        pitch: 5, //音高，-1为占位符,-2延音符
                                        pitchLevel: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                    }
                                ]
                            },
                            {
                                part: [
                                    //多声部
                                    {
                                        pitch: 6, //音高，-1为占位符,-2延音符
                                        pitchLevel: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        type: 'STAVE',
                        note: [
                            {
                                spectrum: [
                                    //线谱
                                    {
                                        type: 'PART', //符号类型，默认PART：PART声部、TEMPO节拍、CLEF谱号、调号KEY_SIGNATURE
                                        part: [
                                            //声部
                                            {
                                                pitch: [
                                                    {
                                                        value: 1, //音高，0表示休止符
                                                        level: 1 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    },
                                                    {
                                                        value: 5, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ], //音高，0表示休止符
                                                pitchTime: 0.5 //时值，可选值：1、0.5、0.25、0.125，1四音符、0.5八分音符、0.25十六分音符、0.125三十二分音符
                                            },
                                            {
                                                pitch: [
                                                    {
                                                        value: 2, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            }
                                        ]
                                    },
                                    {
                                        type: 'PART', //符号类型，默认PART：PART声部、TEMPO节拍、CLEF谱号、调号KEY_SIGNATURE
                                        part: [
                                            //声部
                                            {
                                                pitch: [
                                                    {
                                                        value: 4, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                spectrum: [
                                    //线谱
                                    {
                                        type: 'PART', //符号类型，默认PART：PART声部、TEMPO节拍、CLEF谱号、调号KEY_SIGNATURE
                                        part: [
                                            //声部
                                            {
                                                pitch: [
                                                    {
                                                        value: 1, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ], //音高，0表示休止符
                                                pitchTime: 0.25 //时值，可选值：1、0.5、0.25、0.125，1四音符、0.5八分音符、0.25十六分音符、0.125三十二分音符
                                            },
                                            {
                                                pitch: [
                                                    {
                                                        value: 2, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            },
                                            {
                                                pitch: [
                                                    {
                                                        value: 3, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            }
                                        ]
                                    },
                                    {
                                        type: 'PART', //符号类型，默认PART：PART声部、TEMPO节拍、CLEF谱号、调号KEY_SIGNATURE
                                        part: [
                                            //声部
                                            {
                                                pitch: [
                                                    {
                                                        value: 4, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                spectrum: [
                                    //线谱
                                    {
                                        type: 'PART', //符号类型，默认PART：PART声部、TEMPO节拍、CLEF谱号、调号KEY_SIGNATURE
                                        part: [
                                            //声部
                                            {
                                                pitch: [
                                                    {
                                                        value: 1, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ], //音高，0表示休止符
                                                pitchTime: 0.125 //时值，可选值：1、0.5、0.25、0.125，1四音符、0.5八分音符、0.25十六分音符、0.125三十二分音符
                                            },
                                            {
                                                pitch: [
                                                    {
                                                        value: 2, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ], //音高，0表示休止符
                                                pitchTime: 0.5 //时值，可选值：1、0.5、0.25、0.125，1四音符、0.5八分音符、0.25十六分音符、0.125三十二分音符
                                            }
                                        ]
                                    },
                                    {
                                        type: 'PART', //符号类型，默认PART：PART声部、TEMPO节拍、CLEF谱号、调号KEY_SIGNATURE
                                        part: [
                                            //声部
                                            {
                                                pitch: [
                                                    {
                                                        value: 4, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                spectrum: [
                                    //线谱
                                    {
                                        type: 'PART', //符号类型，默认PART：PART声部、TEMPO节拍、CLEF谱号、调号KEY_SIGNATURE
                                        part: [
                                            //声部
                                            {
                                                pitch: [
                                                    {
                                                        value: 1, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            },
                                            {
                                                pitch: [
                                                    {
                                                        value: 2, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            },
                                            {
                                                pitch: [
                                                    {
                                                        value: 3, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            }
                                        ]
                                    },
                                    {
                                        type: 'PART', //符号类型，默认PART：PART声部、TEMPO节拍、CLEF谱号、调号KEY_SIGNATURE
                                        part: [
                                            //声部
                                            {
                                                type: 'string', //类型，默认NOTE：音符NOTE
                                                pitch: [
                                                    {
                                                        value: 3, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        line: [
                            //音符连线
                            {
                                end: 2,
                                part: 0,
                                save: 0,
                                start: 0
                            }
                        ]
                    }
                ]
            },
            {
                track: [
                    //音轨
                    {
                        type: 'NUMBERED', //类型：EMPTY占位轨、NUMBERED简谱、STAVE五线谱、LYRIC歌词
                        note: [
                            {
                                part: [
                                    //多声部
                                    {
                                        pitch: 2, //音高，-1为占位符,-2延音符
                                        pitchLevel: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                    }
                                ]
                            },
                            {
                                part: [
                                    //多声部
                                    {
                                        type: 'EMPTY',
                                        pitch: 1, //音高，-1为占位符,-2延音符
                                        pitchLevel: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                    }
                                ]
                            },
                            {
                                part: [
                                    //多声部
                                    {
                                        type: 'DELAY'
                                    }
                                ]
                            },
                            {
                                part: [
                                    //多声部
                                    {
                                        type: 'EMPTY',
                                        pitch: 1, //音高，-1为占位符,-2延音符
                                        pitchLevel: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        type: 'NUMBERED', //类型：EMPTY占位轨、NUMBERED简谱、STAVE五线谱、LYRIC歌词
                        note: [
                            {
                                part: [
                                    //多声部
                                    {
                                        pitch: 2, //音高，-1为占位符,-2延音符
                                        pitchLevel: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                    }
                                ]
                            },
                            {
                                part: [
                                    //多声部
                                    {
                                        type: 'EMPTY'
                                    }
                                ]
                            },
                            {
                                part: [
                                    //多声部
                                    {
                                        type: 'DELAY',
                                        pitch: 2, //音高，-1为占位符,-2延音符
                                        pitchLevel: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                    }
                                ]
                            },
                            {
                                part: [
                                    //多声部
                                    {
                                        type: 'EMPTY'
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        type: 'STAVE',
                        note: [
                            {
                                spectrum: [
                                    //线谱
                                    {
                                        type: 'PART', //符号类型，默认PART：PART声部、TEMPO节拍、CLEF谱号、调号KEY_SIGNATURE
                                        part: [
                                            //声部
                                            {
                                                type: 'string', //类型，默认NOTE：音符NOTE
                                                pitch: [
                                                    {
                                                        value: 1, //音高，0表示休止符
                                                        level: 1 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            },
                                            {
                                                type: 'string', //类型，默认NOTE：音符NOTE
                                                pitch: [
                                                    {
                                                        value: 2, //音高，0表示休止符
                                                        level: 1 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            }
                                        ]
                                    },
                                    {
                                        type: 'PART', //符号类型，默认PART：PART声部、TEMPO节拍、CLEF谱号、调号KEY_SIGNATURE
                                        part: [
                                            //声部
                                            {
                                                pitch: [
                                                    {
                                                        value: 2, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                spectrum: [
                                    //线谱
                                    {
                                        type: 'PART', //符号类型，默认PART：PART声部、TEMPO节拍、CLEF谱号、调号KEY_SIGNATURE
                                        part: [
                                            //声部
                                            {
                                                pitch: [
                                                    {
                                                        value: 1, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            },
                                            {
                                                pitch: [
                                                    {
                                                        value: 2, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            },
                                            {
                                                pitch: [
                                                    {
                                                        value: 3, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            }
                                        ]
                                    },
                                    {
                                        type: 'PART', //符号类型，默认PART：PART声部、TEMPO节拍、CLEF谱号、调号KEY_SIGNATURE
                                        part: [
                                            //声部
                                            {
                                                pitch: [
                                                    {
                                                        value: 1, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                spectrum: [
                                    //线谱
                                    {
                                        type: 'PART', //符号类型，默认PART：PART声部、TEMPO节拍、CLEF谱号、调号KEY_SIGNATURE
                                        part: [
                                            //声部
                                            {
                                                pitch: [
                                                    {
                                                        value: 1, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            },
                                            {
                                                pitch: [
                                                    {
                                                        value: 2, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            },
                                            {
                                                pitch: [
                                                    {
                                                        value: 3, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            }
                                        ]
                                    },
                                    {
                                        type: 'PART', //符号类型，默认PART：PART声部、TEMPO节拍、CLEF谱号、调号KEY_SIGNATURE
                                        part: [
                                            //声部
                                            {
                                                pitch: [
                                                    {
                                                        value: 4, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                spectrum: [
                                    //线谱
                                    {
                                        type: 'PART', //符号类型，默认PART：PART声部、TEMPO节拍、CLEF谱号、调号KEY_SIGNATURE
                                        part: [
                                            //声部
                                            {
                                                pitch: [
                                                    {
                                                        value: 1, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            },
                                            {
                                                pitch: [
                                                    {
                                                        value: 2, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            },
                                            {
                                                pitch: [
                                                    {
                                                        value: 3, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            }
                                        ]
                                    },
                                    {
                                        type: 'PART', //符号类型，默认PART：PART声部、TEMPO节拍、CLEF谱号、调号KEY_SIGNATURE
                                        part: [
                                            //声部
                                            {
                                                pitch: [
                                                    {
                                                        value: 4, //音高，0表示休止符
                                                        level: 0 //音高级别：-2倍低音、-1低音、0中音、1中高、2倍高音,
                                                    }
                                                ] //音高，0表示休止符
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
        tie: [
            //简谱延音线
            {
                startContent: 1, //起始小节
                endContent: 2, //结束小节
                track: 1, //音轨
                start: 2, //开始位置
                end: 0 //结束位置
            },
            {
                startContent: 0, //起始小节
                endContent: 1, //结束小节
                track: 0, //音轨
                start: 2, //开始位置
                end: 3 //结束位置
            }
        ]
    };
}
