import request from '@/utils/request';

// 曲谱分类
export function musicType(data) {
    return request({
        url: '/api/category/primary',
        method: 'get',
        params: data
    });
}

// 曲谱分类下的列表
export function musics(data) {
    return request({
        url: '/api/musicScore/page',
        method: 'post',
        data
    });
}

// 曲谱分类下的列表
export function musicsDetail(data) {
    return request({
        url: '/api/core/musicScore/detail',
        method: 'get',
        params: data
    });
}

// 曲谱点赞
export function musicsPraise(data) {
    return request({
        url: '/api/core/musicScore/links',
        method: 'get',
        params: data
    });
}

// 曲谱收藏/取消
export function musicsCollection(data) {
    return request({
        url: '/api/core/musicScore/collect',
        method: 'get',
        params: data
    });
}

// 热门专辑
export function hotAlbum(data) {
    return request({
        url: '/api/album',
        method: 'get',
        params: data
    });
}

// 推荐分类
export function recommend(data) {
    return request({
        url: '/api/category/recommend',
        method: 'get',
        params: data
    });
}
