<template>
    <div class="contact-wrap">
        <div class="company__wrap">
            <div class="content__wrap">
                <h1 class="company__name">福建词音曲信息技术有限公司</h1>
                <p class="company__introduce">
                    福州词音曲信息技术有限公司于2017年12月08日成立。法定代表人林朝,公司经营范围包括：计算机信息、计算机软硬件的技术开发、技术服务；教育信息咨询；文化艺术交流活动组织策划；电子产品、乐器、计算机软硬件的批发、代购代销等。
                </p>
                <div class="line"></div>
                <div class="company__info">
                    <div class="info__item">
                        <div class="label__wrap">
                            <i class="label__icon contact"></i>
                            <span class="label__text">联系电话</span>
                        </div>
                        <span class="info__text">4006-218-818</span>
                        <span class="info__text">4007-218-616</span>
                    </div>
                    <div class="info__item">
                        <div class="label__wrap">
                            <i class="label__icon kefu"></i>
                            <span class="label__text">QQ客服</span>
                        </div>
                        <button class="service-button" @click="handleContactService">客服1</button>
                        <button class="service-button" @click="handleContactService">客服2</button>
                    </div>
                    <div class="info__item">
                        <div class="label__wrap">
                            <i class="label__icon adderss"></i>
                            <span class="label__text">公司地址</span>
                        </div>
                        <span class="info__text">福州市鼓楼区仙塔街3层</span>
                        <span class="info__text">津泰商厦</span>
                    </div>
                    <div class="info__item">
                        <div class="label__wrap">
                            <i class="label__icon weixin"></i>
                            <span class="label__text">官方微信</span>
                        </div>
                        <span class="info__text">qupuwang</span>
                        <img
                            class="weixin__img"
                            src="https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1602671965207&di=2743f4fca0c3a5e6c598e441469279ac&imgtype=0&src=http%3A%2F%2Fimg.xjishu.com%2Fimg%2Fzl%2F2018%2F6%2F30%2F1241359458913.gif"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="opinion__wrap">
            <p class="opinion__title">Writer us</p>
            <div class="opinion__content">
                <div class="content__left">
                    <div class="input__wrap">
                        <span class="input__label">姓名</span>
                        <input class="input" type="text" placeholder="请输入姓名" />
                    </div>
                    <div class="input__wrap">
                        <span class="input__label">邮箱</span>
                        <input class="input" type="text" placeholder="请输入您的邮箱" />
                    </div>
                    <div class="input__wrap">
                        <span class="input__label">联系电话</span>
                        <input class="input" type="text" placeholder="请输入您的联系电话" />
                    </div>
                </div>
                <div class="content__right">
                    <div class="input__wrap">
                        <span class="input__label">姓名</span>
                        <textarea class="input" name="txt" clos=",50" rows="14" warp="virtual" placeholder="请输入您的留言"></textarea>
                    </div>
                    <button class="submit__button">提交</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Contact',
    data() {
        return {};
    },
    methods: {
        // 联系客服
        handleContactService() {}
    }
};
</script>

<style scoped lang="scss">
.contact-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #f5f7fa;
    .company__wrap {
        width: 100%;
        background-size: 100% 100%;
        background-color: #3e4961;
        background-repeat: no-repeat;
        background-image: url(../../assets/image/company-bg.png);
        .content__wrap {
            width: 1200px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            flex-direction: column;
            .company__name {
                color: #ffffff;
                font-size: 36px;
                font-weight: bold;
                margin: 60px 0 40px 0;
            }
            .company__introduce {
                width: 100%;
                margin: 0;
                opacity: 0.6;
                color: #ffffff;
                font-size: 18px;
                line-height: 32px;
                text-align: center;
            }
            .line {
                width: 100%;
                height: 2px;
                opacity: 0.1;
                margin-top: 60px;
                background-color: #ffffff;
            }
            .company__info {
                width: 100%;
                padding: 60px 0;
                display: flex;
                justify-content: space-between;
                .info__item {
                    display: flex;
                    flex-direction: column;
                    .label__wrap {
                        margin-bottom: 30px;
                        display: flex;
                        align-items: center;
                        .label__icon {
                            width: 30px;
                            height: 30px;
                            margin-right: 10px;
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                        }
                        .label__icon.contact {
                            background-image: url(../../assets/icon/contact.png);
                        }
                        .label__icon.kefu {
                            background-image: url(../../assets/icon/kefu.png);
                        }
                        .label__icon.adderss {
                            background-image: url(../../assets/icon/adderss.png);
                        }
                        .label__icon.weixin {
                            background-image: url(../../assets/icon/weixin.png);
                        }
                        .label__text {
                            opacity: 0.4;
                            color: #ffffff;
                            font-size: 16px;
                            white-space: nowrap;
                        }
                    }
                    .info__text {
                        color: #ffffff;
                        font-size: 18px;
                        font-weight: bold;
                        margin-bottom: 20px;
                    }
                    .service-button {
                        width: 110px;
                        height: 44px;
                        border: none;
                        color: #ffffff;
                        font-size: 18px;
                        font-weight: bold;

                        border-radius: 12px;
                        background-color: #4196ff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .service-button:last-child {
                        margin-top: 20px;
                    }
                    .weixin__img {
                        width: 120px;
                        height: 120px;
                        border: 2px dashed #c2cad4;
                    }
                }
            }
        }
    }
    .opinion__wrap {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        .opinion__title {
            color: #e1e3e6;
            font-size: 86px;
            font-weight: bold;
            position: relative;
        }
        .opinion__title:after {
            position: absolute;
            content: '写给我们';
            left: 50%;
            top: 70px;
            transform: translateX(-50%);
            color: #303133;
            font-size: 36px;
        }
        .opinion__content {
            height: 500px;
            width: 1200px;
            margin-bottom: 200px;
            background-color: #ffffff;
            display: flex;
            .content__left {
                width: 485px;
                padding: 0 80px;
                margin: 60px 0 110px 0;
                border-right: 1px solid #ebedf2;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .input__wrap {
                    display: flex;
                    flex-direction: column;
                    .input__label {
                        color: #909399;
                        font-size: 16px;
                        margin-bottom: 10px;
                    }
                    .input {
                        height: 44px;
                        width: 320px;
                        padding: 0 20px;
                        font-size: 16px;
                        border-radius: 12px;
                        border: 1px solid #ebedf2;
                    }
                }
            }
            .content__right {
                width: calc(100% - 485px);
                margin-top: 60px;
                border-right: 1px solid #ebedf2;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-between;
                .input__wrap {
                    display: flex;
                    flex-direction: column;
                    .input__label {
                        color: #909399;
                        font-size: 16px;
                        margin-bottom: 10px;
                    }
                    .input {
                        width: 570px;
                        resize: none;
                        outline: none;
                        padding: 20px;
                        font-size: 16px;
                        border-radius: 12px;
                        border: 1px solid #ebedf2;
                    }
                }
                .submit__button {
                    width: 100%;
                    height: 88px;
                    border: none;
                    color: #ffffff;
                    font-size: 24px;
                    background-color: #4196ff;
                }
            }
        }
    }
}
</style>
