<template>
    <div class="music-type-wrap">
        <div class="type__list" v-for="(itemType, typeIndex) in types" :key="typeIndex">
            <div class="list__name">{{ itemType.name }}</div>
            <div class="list__wrpa">
                <router-link class="list__item" active-class="list__item--active" tag="span" :to="`/home/music/${item.id}`" v-for="(item, index) in itemType.children" :key="index">
                    {{ item.name }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { musicType } from '@/api/music';
export default {
    name: 'MusicType',
    data() {
        return {
            types: [], // 曲谱分类
            typeId: '' // 分类ID
        };
    },
    created() {
        this.getMusicType();
    },
    watch: {
        $route(val) {
            this.typeId = val.params.typeid;
        }
    },
    methods: {
        // 获取曲谱分类
        getMusicType() {
            musicType().then(res => {
                this.types = res.data.data;
            });
        }
    }
};
</script>

<style scoped lang="scss">
.music-type-wrap {
    margin: 40px 0;
    display: flex;
    .type__list {
        min-width: 200px;
        display: flex;
        align-items: center;
        .list__name {
            width: 32px;
            color: #ffffff;
            font-size: 16px;
            padding: 12px 8px;
            border-radius: 8px;
            display: flex;
            align-items: center;
        }
        .list__wrpa {
            display: flex;
            flex-wrap: wrap;
            .list__item {
                color: #5f6166;
                cursor: pointer;
                font-size: 16px;
                margin: 10px 10px;
            }
            .list__item--active {
                color: #4196ff;
            }
            .list__item:hover {
                color: #4196ff;
            }
        }
    }
    .type__list:nth-child(1n) .list__name {
        background-color: #ff8116;
    }
    .type__list:nth-child(2n) .list__name {
        background-color: #4196ff;
    }
    .type__list:nth-child(3n) .list__name {
        background-color: #ff4184;
    }
}
</style>
