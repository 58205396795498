<template>
    <div class="music-controller-wrap">
        <div class="music-controller__prepare" v-if="playState.isPlay && playState.isCountIn">
            <div class="prepare__item" :class="{ 'prepare__item--active': playState.countIn == index }" v-for="(item, index) in playOption.countIn" :key="index"></div>
        </div>
        <div class="music-controller__content">
            <div class="controller__left">
                <img class="music__img" :src="musicInfo.cover" alt="" />
                <div class="info__wrap">
                    <span class="info__title">{{ musicInfo.title }}</span>
                    <span class="info__singer">{{ musicInfo.authorSing }}</span>
                </div>
            </div>
            <div class="controller__cneter">
                <i class="controller__button el-icon-refresh-right" title="重新播放" @click="handleReplay"></i>
                <i v-show="!playState.isPlay" class="controller__button el-icon-video-play" title="开始播放" @click="handlePlay"></i>
                <i v-show="playState.isPlay" class="controller__button el-icon-video-pause" title="暂停播放" @click="handleSuspend"></i>
            </div>
            <div class="controller__right">
                <div class="controller__right__speed">
                    <span class="label__text">播放速度</span>
                    <el-input-number
                        v-model="playOption.speed"
                        @change="handleSpeedChange"
                        :min="30"
                        :max="240"
                        :step="10"
                        step-strictly
                        label="播放速度"
                        size="mini"
                    ></el-input-number>
                </div>
                <div class="controller__right__setting" @click="handleClickSetting">
                    <i class="controller__button el-icon-s-tools"></i>
                    <span class="label__text">设置</span>
                </div>
            </div>
        </div>
        <el-dialog title="播放设置" :visible.sync="showSetting" width="550px" :modal="false">
            <div class="setting__content">
                <div class="content__top">
                    <div class="content__left">
                        <span class="label__text">播放设置</span>
                        <div class="setting_wrap">
                            <el-radio-group v-model="playOption.from">
                                <el-radio label="start">从头开始</el-radio>
                                <el-radio label="select">从选定位置播放</el-radio>
                                <el-radio label="index">从指定位置播放</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <div class="content__right">
                        <div class="setting_item">
                            <span class="label__text">播放速度</span>
                            <el-input-number v-model="playOption.speed" :min="30" :max="240" :step="10" step-strictly label="播放速度" size="mini"></el-input-number>
                        </div>
                        <div class="setting_item">
                            <span class="label__text">预备拍</span>
                            <el-input-number v-model="playOption.countIn" :min="0" :max="8" label="预备怕" size="mini"></el-input-number>
                        </div>
                        <div class="setting_item" v-if="playOption.from == 'index'">
                            <span class="label__text">起始小节</span>
                            <el-input-number v-model="playState.startIndex" :min="1" :max="100" label="起始小节" size="mini"></el-input-number>
                        </div>
                    </div>
                </div>
                <div class="content__bottom">
                    <el-table :data="[0]" border fit highlight-current-row>
                        <el-table-column label="小节" align="center" fixed width="50px">
                            <template>轨道</template>
                        </el-table-column>
                        <el-table-column v-for="(info, index) in playInfo" :key="index" :label="info.start + 1 + '-' + (info.end + 1)" align="center">
                            <template>
                                <div v-for="(track, trackIndex) in info.tracks" :key="trackIndex">
                                    <el-checkbox v-model="track.selected" v-if="track.play">{{ track.name }}</el-checkbox>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleSettingPlay">播放</el-button>
                <el-button @click="showSetting = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'MusicController',
    props: {
        musicInfo: {
            type: Object
        }
    },
    data() {
        return {
            showSetting: false // 显示设置
        };
    },
    computed: {
        ...mapState(['playState', 'playOption', 'playInfo'])
    },
    methods: {
        // 速度改变
        handleSpeedChange() {
            this.$emit('speed', this.playOption);
        },
        // 开始播放
        handlePlay() {
            this.$throttle(() => {
                this.showSetting = false;
                this.$emit('play', this.playOption);
            });
        },
        // 设置播放
        handleSettingPlay() {
            this.$throttle(() => {
                this.$emit('play', this.playOption);
                this.showSetting = false;
            });
        },
        // 暂停播放
        handleSuspend() {
            this.$throttle(() => {
                this.$emit('suspend', this.playOption);
            });
        },
        // 重新播放
        handleReplay() {
            this.$throttle(() => {
                this.playOption.from = 'start';
                this.$emit('replay', this.playOption);
            });
        },
        // 点击设置
        handleClickSetting() {
            this.showSetting = true;
            this.$emit('setting');
        }
    }
};
</script>

<style scoped lang="scss">
.music-controller-wrap {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 65px;
    z-index: 999;
    background-color: #ffffff;
    box-shadow: 0px 0px 7px 0px #dfdfdf;
    .music-controller__prepare {
        position: absolute;
        left: 50%;
        top: -65px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-50%);
        .prepare__item {
            width: 38px;
            height: 38px;
            margin-right: 15px;
            border-radius: 100%;
            background-color: #4296ff;
        }
        .prepare__item--active {
            background-color: #13ce66;
        }
    }
    .music-controller__content {
        height: 100%;
        width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .controller__left {
            display: flex;
            align-items: center;
            .music__img {
                width: 48px;
                height: 48px;
                border-radius: 4px;
                margin-right: 20px;
                border: 2px solid #ffffff;
                box-shadow: 0px 0px 10px 0px #b3d5ff;
            }
            .info__wrap {
                display: flex;
                flex-direction: column;
                .info__title {
                    color: #4a4a4a;
                    font-size: 14px;
                    margin-bottom: 5px;
                }
                .info__singer {
                    color: #989898;
                    font-size: 12px;
                }
            }
        }
        .controller__cneter {
            width: 100px;
            margin: 0 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .controller__right {
            display: flex;
            align-items: center;
            .controller__right__speed {
                display: flex;
                align-items: center;
                ::v-deep .el-input-number {
                    border-radius: 4px;
                    border: 1px solid #b3d5ff;
                    .el-input {
                        position: relative;
                        .el-input__inner {
                            border: none;
                        }
                    }
                    &::after {
                        content: '';
                        z-index: -10;
                        position: absolute;
                        top: -1px;
                        bottom: -1px;
                        left: -1px;
                        right: -1px;
                        border-radius: 4px;
                        border: 1px solid #b3d5ff;
                        transform-origin: center;
                        transform: scale(1);
                    }
                    &:hover::after {
                        opacity: 0;
                        transform: scale(1.75);
                        transform-origin: center;
                        transition: all 0.75s ease-in-out;
                    }
                    .el-input-number__decrease {
                        background-color: #b3d5ff;
                        i {
                            color: #ffffff;
                        }
                    }
                    .el-input-number__increase {
                        background-color: #b3d5ff;
                        i {
                            color: #ffffff;
                        }
                    }
                }
                .label__text {
                    color: #555353;
                    font-size: 14px;
                    margin-right: 20px;
                }
            }
            .controller__right__setting {
                margin-left: 60px;
                display: flex;
                align-items: center;
                .controller__button {
                    width: 28px;
                    height: 28px;
                    font-size: 20px;
                }
                .label__text {
                    color: #555353;
                    font-size: 14px;
                    margin-left: 12px;
                }
            }
        }
        .controller__button {
            width: 40px;
            height: 40px;
            font-size: 28px;
            border-radius: 100%;
            color: #ffffff;
            background-color: #b3d5ff;
            display: flex;
            cursor: pointer;
            position: relative;
            align-items: center;
            justify-content: center;
        }
        .controller__button::after {
            content: '';
            z-index: -10;
            position: absolute;
            top: -1px;
            bottom: -1px;
            left: -1px;
            right: -1px;
            border-radius: 100%;
            border: 1px solid #b3d5ff;
            transform-origin: center;
            transform: scale(1);
        }
        .controller__button::before {
            margin-top: 1px;
            margin-left: 1px;
        }
        .controller__button:hover::after {
            opacity: 0;
            transform: scale(1.75);
            transform-origin: center;
            transition: all 0.75s ease-in-out;
        }
    }
    ::v-deep .el-dialog {
        border-radius: 10px;
        box-shadow: 0px 11px 18px 0px #bdcadc;
        .el-dialog__header {
            height: 48px;
            padding: 0 20px;
            background-color: #f5f7f9;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-dialog__title {
                color: #515151;
                font-size: 16px;
            }
        }
        .el-dialog__body {
            padding: 0;
            .setting__content {
                padding: 20px;
                display: flex;
                flex-direction: column;
                .content__top {
                    display: flex;
                    .content__left {
                        margin-right: 80px;
                        display: flex;
                        flex-direction: row;
                        .label__text {
                            color: #515151;
                            height: 28px;
                            font-size: 14px;
                            line-height: 28px;
                            margin-right: 15px;
                            white-space: nowrap;
                        }
                        .setting_wrap {
                            display: flex;
                            flex-direction: column;
                            .el-radio {
                                height: 28px;
                                margin-bottom: 10px;
                                display: flex;
                                align-items: center;
                            }
                        }
                    }
                    .content__right {
                        display: flex;
                        flex-direction: column;
                        .setting_item {
                            margin-bottom: 10px;
                            display: flex;
                            align-items: center;
                            .label__text {
                                width: 60px;
                                margin-right: 15px;
                            }
                        }
                    }
                }
            }
        }
        .el-dialog__footer {
            display: flex;
            align-items: center;
            justify-content: center;
            .dialog-footer {
                display: flex;
                align-items: center;
                .el-button {
                    padding: 0;
                    height: 36px;
                    width: 135px;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
</style>
