import qs from 'qs';
import axios from 'axios';
import router from '@/router';
import store from '@/utils/state';
import { Message, Notification } from 'element-ui';
import { getToken, removeToken } from '@/utils/auth';

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 60000 // request timeout
});

// request interceptor
service.interceptors.request.use(
    config => {
        config.data = config.data || {};
        config.errorCode = config.errorCode || {};
        // do something before request is sent
        if (getToken() != '') {
            // let each request carry token --['X-Token'] as a custom key.
            // please modify it according to the actual situation.
            config.headers['token'] = getToken();
            config.data['token'] = getToken();
        }
        if (config.method.toLocaleLowerCase() === 'post') {
            config.headers['content-type'] = 'application/x-www-form-urlencoded;charset=utf-8';
            config.data = qs.stringify(config.data);
        }
        // console.log(config);
        return config;
    },
    error => {
        // do something with request error
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code.
     */
    response => {
        const res = response.data;
        // if the custom code is not 20000, it is judged as an error.
        if (res.success) {
            return res;
        }

        res.errors &&
            res.errors.forEach(item => {
                setTimeout(() => {
                    Notification.error({
                        // title: item.name,
                        message: item.msg
                    });
                }, 500);
            });
        if (response.config.errorCode[res.respCode]) {
            response.config.errorCode[res.respCode](res);
        } else if (errorCode[res.respCode]) {
            errorCode[res.respCode](res);
        } else {
            Message({
                message: res.respMsg || '操作失败',
                type: 'error',
                duration: 5 * 1000
            });
        }
        return Promise.reject(res || 'error');
    },
    error => {
        console.log('err' + error); // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        });
        return Promise.reject(error);
    }
);

const errorCode = {
    '1000': () => {
        Message({
            message: '登录超时',
            type: 'error',
            duration: 5 * 1000
        });
        setTimeout(() => {
            removeToken();
            store.setState('userInfo', null);
            var path = '/login?redirect=' + router.currentRoute.fullPath;
            router.replace({
                path: path
            });
        }, 1000);
    }
};

export default service;
