<template>
    <div class="login-wrap">
        <Header></Header>
        <Navigation></Navigation>
        <div class="main__content">
            <div class="tabs__wrap">
                <div class="tabs__item" :class="{ 'tabs__item--active': tabIndex == 0 }" @click="tabIndex = 0">密码登录</div>
                <div class="tabs__item" :class="{ 'tabs__item--active': tabIndex == 1 }" @click="tabIndex = 1">验证码登录</div>
            </div>
            <!-- 普通登录 -->
            <div class="content__wrap" v-if="tabIndex == 0">
                <div class="input__wrap">
                    <i class="input__icon phone"></i>
                    <div class="input__line"></div>
                    <input class="input" type="text" v-model="userName" placeholder="请输入账号" />
                </div>
                <div class="input__wrap password">
                    <i class="input__icon password"></i>
                    <div class="input__line"></div>
                    <input class="input" type="password" v-model="passWord" placeholder="请输入密码" />
                </div>
                <button class="theme-button login__button" @click="handleAccountLogin">登录</button>
                <div class="other__wrap">
                    <span class="other__button">忘记密码</span>
                    <div class="line"></div>
                    <span class="other__button" @click="handleToPage('register')">账号注册</span>
                </div>
            </div>
            <!-- 验证码登录 -->
            <div class="content__wrap" v-if="tabIndex == 1">
                <div class="input__wrap">
                    <i class="input__icon phone"></i>
                    <div class="input__line"></div>
                    <input class="input" type="text" v-model="mobile" placeholder="请输入手机号" />
                </div>
                <div class="figure__wrap">
                    <div class="input__wrap">
                        <i class="input__icon figure"></i>
                        <div class="input__line"></div>
                        <input class="input" type="text" v-model="captcha" placeholder="请输入图形验证码" />
                    </div>
                    <img class="figure__img" :src="captchaImg" alt="" @click="getCaptcha" />
                </div>
                <div class="input__wrap">
                    <i class="input__icon code"></i>
                    <div class="input__line"></div>
                    <input class="input" type="number" v-model="code" placeholder="请输入短信验证码" />
                    <div class="input__line"></div>
                    <div class="send__button" :class="{ 'send__button--disable': !isSend }" @click="getCode">{{ sendButtonText }}</div>
                </div>
                <button class="theme-button login__button" @click="handleCodeLogin">登录</button>
                <div class="other__wrap">
                    <span class="other__button">忘记密码</span>
                    <div class="line"></div>
                    <span class="other__button" @click="handleToPage('register')">账号注册</span>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Navigation from '@/components/Navigation';
import { login, codeLogin, code, captcha } from '@/api/user';
import { setToken } from '@/utils/auth';
export default {
    components: {
        Header,
        Footer,
        Navigation
    },
    data() {
        return {
            tabIndex: 0,
            userName: '', // 账号
            passWord: '', // 密码
            mobile: '', // 手机
            code: '', // 短信验证码
            captcha: '', // 图形验证码
            captchaKey: '', // 图形验证码Key
            captchaImg: '', // 图形验证码图片
            isSend: true, // 是否可以发送短信验证码
            sendButtonText: '发送验证码', // 发送验证码按钮
            timer: null // 计时器
        };
    },
    created() {
        this.getCaptcha();
    },
    methods: {
        // 普通登录
        handleAccountLogin() {
            login({
                userName: this.userName,
                passWord: this.passWord
            }).then(res => {
                if (res.success) {
                    setToken(res.data.token);
                    this.$setState('token', res.data.token);
                    this.$router.push({ name: 'index' });
                }
            });
        },
        // 验证码登录
        handleCodeLogin() {
            if (!this.mobile) {
                this.$message({
                    message: '请先填写手机号哦~',
                    type: 'warning'
                });
                return;
            } else if (!this.captcha) {
                this.$message({
                    message: '请先填写图形验证码~',
                    type: 'warning'
                });
                return;
            } else if (!this.code) {
                this.$message({
                    message: '请先填写短信验证码~',
                    type: 'warning'
                });
                return;
            }
            codeLogin({
                mobile: this.mobile,
                code: this.code,
                captcha: this.captcha,
                key: this.captchaKey,
                type: 'MS_LOGIN'
            }).then(res => {
                if (res.success) {
                    setToken(res.data.token);
                    this.$setState('token', res.data.token);
                    this.$router.push({ name: 'index' });
                }
            });
        },
        // 获取图形验证码
        getCaptcha() {
            captcha().then(res => {
                this.captchaKey = res.data.key;
                this.captchaImg = res.data.image;
            });
        },
        // 获取短信验证码
        getCode() {
            if (!this.mobile) {
                this.$message({
                    message: '请先填写手机号哦~',
                    type: 'warning'
                });
                return;
            } else if (!this.captcha) {
                this.$message({
                    message: '请先填写图形验证码~',
                    type: 'warning'
                });
                return;
            } else if (this.isSend) {
                code({
                    phone: this.mobile,
                    captcha: this.captcha,
                    key: this.captchaKey,
                    type: 'MS_LOGIN'
                }).then(res => {
                    if (res.success) {
                        let num = 59;
                        this.isSend = false;
                        this.timer = setInterval(() => {
                            num--;
                            this.sendButtonText = `发送中(${num})`;
                            if (num == 0) {
                                clearInterval(this.timer);
                                this.sendButtonText = '发送验证码';
                                this.isSend = true;
                            }
                        }, 1000);
                    }
                });
            }
        },
        // 跳转页面
        handleToPage(type) {
            this.$router.push({ name: type });
        }
    }
};
</script>

<style scoped lang="scss">
.login-wrap {
    display: flex;
    flex-direction: column;
    .main__content {
        width: 1200px;
        margin: 60px auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        .tabs__wrap {
            width: 480px;
            display: flex;
            align-items: center;
            .tabs__item {
                width: 50%;
                height: 50px;
                color: #bfc3cc;
                font-size: 18px;
                cursor: default;
                font-weight: bold;
                transition: all 0.3s;
                border-bottom: 1px solid #e5e5e5;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .tabs__item--active {
                color: #4195ff;
                font-size: 26px;
                border-bottom: 2px solid #3f95ff;
            }
        }
        .content__wrap {
            width: 480px;
            padding: 60px 0;
            .figure__wrap {
                display: flex;
                align-items: flex-start;
                .input__wrap {
                    width: 312px !important;
                }
                .figure__img {
                    width: 150px;
                    height: 60px;
                    margin-left: 20px;
                    border-radius: 12px;
                    display: inline-block;
                }
            }
            .input__wrap {
                width: 100%;
                height: 60px;
                flex-shrink: 0;
                padding: 17px 30px;
                margin-bottom: 30px;
                border-radius: 12px;
                background: #ffffff;
                border: 1px solid #e5e5e4;
                display: flex;
                align-items: center;
                .input__icon {
                    width: 28px;
                    height: 26px;
                    flex-shrink: 0;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
                .input__line {
                    width: 1px;
                    height: 100%;
                    margin: 0 30px;
                    background-color: #eaedf2;
                }
                .input {
                    width: 100%;
                    border: none;
                    font-size: 18px;
                }
                .input__icon.phone {
                    background-size: 18px 26px;
                    background-image: url(../../assets/icon/phone.png);
                }
                .input__icon.password {
                    background-size: 20px 26px;
                    background-image: url(../../assets/icon/password.png);
                }
                .input__icon.figure {
                    background-size: 24px 24px;
                    background-image: url(../../assets/icon/figure.png);
                }
                .input__icon.code {
                    background-size: 28px 22px;
                    background-image: url(../../assets/icon/code.png);
                }
                .send__button {
                    color: #4195ff;
                    font-size: 18px;
                    cursor: pointer;
                    white-space: nowrap;
                }
                .send__button--disable {
                    color: #c0c4cc;
                }
            }
            .input__wrap.password {
                margin-bottom: 0;
            }
            .login__button {
                width: 100%;
                cursor: pointer;
                margin-top: 60px;
            }
            .other__wrap {
                font-size: 18px;
                margin-top: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                .line {
                    width: 2px;
                    height: 18px;
                    margin: 0 30px;
                    background-color: #8f9299;
                }
                .other__button {
                    color: #8f9299;
                }
                .other__button:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
    }
}
</style>
