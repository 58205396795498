<template>
    <div class="plate-header-wrap" :style="{ margin: `${margin}px 0` }">
        <div class="header__left">
            <span class="title" :style="`font-size: ${titleSize}px;`">{{ title }}</span>
            <i class="icon" v-if="showIcon"></i>
        </div>
        <div class="header__right" v-if="showMore" @click="handleClickMore">更多></div>
    </div>
</template>

<script>
export default {
    name: 'PlateHeader',
    props: {
        title: {
            type: String,
            default: '热门推荐'
        },
        titleSize: {
            type: String,
            default: '24'
        },
        showIcon: {
            type: Boolean,
            default: true
        },
        showMore: {
            type: Boolean,
            default: true
        },
        margin: {
            type: [String, Number],
            default: 0
        }
    },
    data() {
        return {};
    },
    methods: {
        // 点击更多
        handleClickMore() {
            this.$emit('clickMore');
        }
    }
};
</script>

<style lang="scss" scoped>
.plate-header-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header__left {
        display: flex;
        align-items: center;
        .title {
            font-size: 24px;
            font-weight: bold;
            margin-right: 10px;
        }
        .icon {
            width: 44px;
            height: 16px;
            background-image: url(../../assets/icon/icon-1.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }
    }
    .header__right {
        color: #4196ff;
        cursor: pointer;
    }
    .header__right:hover {
        text-decoration: underline;
    }
}
</style>
