<template>
    <div class="music-detail-wrap">
        <!-- 头部 -->
        <div class="header__wrap">
            <span class="music__title">{{ detail.title }}</span>
            <span class="music__date">{{ detail.createDate }}</span>
            <span class="music__type">{{ detail.names.join(' · ') }}</span>
        </div>
        <!-- 介绍 -->
        <div class="introduce__wrap">
            <PlateHeader title="创作思路" :margin="30" :showMore="false"></PlateHeader>
            <div class="cntent__text">{{ detail.ideas }}</div>
        </div>
        <!-- 乐谱 -->
        <div class="music__wrap">
            <PlateHeader title="歌曲乐谱" :margin="30" :showMore="false"></PlateHeader>
            <!-- 谱子组件 -->
            <MusicScore ref="musicScore" @save="save"></MusicScore>
        </div>
        <!-- 操作相关 -->
        <div class="operation__wrap">
            <div class="left__wrap"></div>
            <div class="praise__button button--hover" @click="handleClickPraise">
                <i class="button__icon"></i>
                <span class="button__text">点赞</span>
                <span class="praise__num">{{ detail.likes }}</span>
            </div>
            <div class="collection__button" @click="handleClickCollection">
                <i class="el-icon-star-on" :class="{ 'el-icon-star-on--active': detail.isCollect }"></i>
                <span class="button__text" :class="{ 'button__text--active': detail.isCollect }">收藏</span>
                <span class="collection__num">{{ detail.collect }}</span>
            </div>
        </div>
        <!-- 音乐控制器 -->
        <MusicController
            :music-info="detail"
            @play="handlePlay"
            @suspend="handleSuspend"
            @replay="handleReplay"
            @speed="handleSpeedChange"
            @setting="handleSetting"
        ></MusicController>
    </div>
</template>

<script>
import MusicScore from '@/components/MusicScore';
import PlateHeader from '@/components/PlateHeader';
import MusicController from '@/components/MusicController';
import { musicsDetail, musicsPraise, musicsCollection } from '@/api/music';
export default {
    name: 'MusicDetail',
    components: {
        MusicScore,
        PlateHeader,
        MusicController
    },
    data() {
        return {
            musicId: '', // 曲谱ID,
            detail: {
                names: []
            }, // 详情
            doc: {} // 谱子
        };
    },
    watch: {
        $route: {
            deep: true, // 深入观察,监听数组值，对象属性值的变化
            immediate: true, // 首次加载的时候执行函数
            handler: function(val) {
                this.musicId = val.params.musicid;
                this.getMusicDetail();
            }
        }
    },
    methods: {
        save(data) {
            console.log(data);
        },
        // 点赞
        handleClickPraise() {
            musicsPraise({
                id: this.musicId
            }).then(res => {
                if (res.success) {
                    this.$message({
                        message: '点赞成功',
                        type: 'success'
                    });
                    musicsDetail({ id: this.musicId }).then(res => {
                        this.detail.likes = res.data.data.likes;
                    });
                }
            });
        },
        // 收藏
        handleClickCollection() {
            musicsCollection({
                id: this.musicId
            }).then(res => {
                if (res.success) {
                    musicsDetail({ id: this.musicId }).then(res => {
                        this.detail.collect = res.data.data.collect;
                        this.detail.isCollect = res.data.data.isCollect;
                        if (this.detail.isCollect) {
                            this.$message({
                                message: '收藏成功',
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: '取消收藏成功',
                                type: 'success'
                            });
                        }
                    });
                }
            });
        },
        // 获取曲谱详情
        getMusicDetail() {
            musicsDetail({ id: this.musicId }).then(res => {
                this.detail = res.data.data;
                this.doc = res.data.doc;
                // 加载打谱
                this.$refs.musicScore.load(res.data.doc);
            });
        },
        // 控制器播放
        handlePlay(data) {
            this.$refs.musicScore.play(data);
        },
        // 控制器暂停
        handleSuspend() {
            this.$refs.musicScore.suspend();
        },
        // 控制器重新播放
        handleReplay(data) {
            this.$refs.musicScore.replay(data);
        },
        // 控制器播放速度控制
        handleSpeedChange(data) {
            this.$refs.musicScore.speed(data);
        },
        // 控制器设置
        handleSetting() {
            this.$refs.musicScore.loadPlayInfo();
        }
    }
};
</script>

<style scoped lang="scss">
.music-detail-wrap {
    width: 1200px;
    margin: 60px auto;
    .header__wrap {
        display: flex;
        flex-direction: column;
        .music__title {
            color: #2f3033;
            font-size: 36px;
        }
        .music__date {
            color: #8f9299;
            margin: 20px 0;
            font-size: 16px;
        }
        .music__type {
            color: #8f9299;
            font-size: 16px;
        }
    }
    .introduce__wrap {
        display: flex;
        flex-direction: column;
        .cntent__text {
            padding: 30px;
            line-height: 36px;
            background-color: #f5f7fa;
        }
    }
    .music__wrap {
        display: flex;
        flex-direction: column;
    }
    .operation__wrap {
        margin: 60px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left__wrap {
            width: 100px;
        }
        .praise__button {
            height: 88px;
            color: #4196ff;
            padding: 0 50px;
            cursor: pointer;
            position: relative;
            border-radius: 44px;
            background-color: #4196ff;
            box-shadow: 0px 10px 20px 0px #c7dfff;
            display: flex;
            align-items: center;
            justify-content: center;
            .button__icon {
                width: 28px;
                height: 28px;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-image: url(../../assets/icon/praise.png);
            }
            .button__text {
                color: #ffffff;
                margin: 0 20px;
                font-size: 24px;
            }
            .praise__num {
                color: #ffffff;
                font-size: 32px;
            }
        }
        .praise__button::before {
            content: '';
            z-index: -1;
            position: absolute;
            top: -1px;
            bottom: -1px;
            left: -1px;
            right: -1px;
            border-radius: 44px;
            border: 1px solid #4196ff;
            transform-origin: center;
            transform: scale(1);
        }
        .praise__button:hover::before {
            transition: all 0.75s ease-in-out;
            transform-origin: center;
            transform: scale(1.75);
            opacity: 0;
        }
        .collection__button {
            height: 44px;
            padding: 0 30px;
            cursor: pointer;
            border-radius: 12px;
            position: relative;
            border: 1px solid #d8dde6;
            display: flex;
            align-items: center;
            justify-content: center;
            .el-icon-star-on {
                color: #dfe1e6;
                font-size: 26px;
            }
            .el-icon-star-on--active {
                color: #4196ff;
            }
            .button__text {
                color: #909399;
                font-size: 18px;
                margin-left: 5px;
                margin-right: 10px;
            }
            .button__text--active {
                color: #4196ff;
            }
            .collection__num {
                color: #909399;
                font-size: 22px;
            }
        }
        .collection__button::before {
            content: '';
            z-index: -1;
            position: absolute;
            top: -1px;
            bottom: -1px;
            left: -1px;
            right: -1px;
            border-radius: 12px;
            border: 1px solid #d8dde6;
            transform-origin: center;
            transform: scale(1);
        }
        .collection__button:hover::before {
            transition: all 0.75s ease-in-out;
            transform-origin: center;
            transform: scale(1.75);
            opacity: 0;
        }
    }
}
</style>
