<template>
    <div class="agreement-wrap">
        <div class="main__content">
            <div class="tabs__wrap">
                <div class="tabs__item tabs__item--active">用户协议</div>
            </div>
            <!-- 用户协议 -->
            <p class="content__wrap" v-html="content"></p>
        </div>
    </div>
</template>

<script>
import { getAgreement } from '@/api/user';
export default {
    data() {
        return {
            content: '<p>测试富文本内容</p>' // 富文本内容
        };
    },
    created() {
        this.getContent();
    },
    methods: {
        // 获取富文本内容
        getContent() {
            getAgreement({
                userProtocol: 'userProtocol'
            }).then(res => {
                if (res.success) {
                    this.content = res.data.userProtocol;
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.agreement-wrap {
    display: flex;
    flex-direction: column;
    .main__content {
        width: 1200px;
        margin: 60px auto;
        display: flex;
        flex-direction: column;
        .tabs__wrap {
            width: 100%;
            display: flex;
            align-items: center;
            .tabs__item {
                width: 100%;
                height: 50px;
                color: #bfc3cc;
                font-size: 18px;
                cursor: default;
                font-weight: bold;
                transition: all 0.3s;
                border-bottom: 1px solid #e5e5e5;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .tabs__item--active {
                color: #4195ff;
                font-size: 26px;
                border-bottom: 2px solid #3f95ff;
            }
        }
        .content__wrap {
            padding-top: 30px;
        }
    }
}
</style>
