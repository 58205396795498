import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/login';
import Register from '../views/register';
import Agreement from '../views/agreement';
import Home from '../views/home';
import Index from '../views/index';
import Music from '../views/music';
import Album from '../views/album';
import MusicDetail from '../views/musicDetail';
import Original from '../views/original';
import Forum from '../views/forum';
import Contact from '../views/contact';

Vue.use(VueRouter);

const routes = [
    {
        path: '*',
        component: Index
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/register',
        name: 'register',
        component: Register
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        children: [
            { path: '/home/agreement', name: 'agreement', component: Agreement },
            { path: '/home/index', name: 'index', component: Index },
            { path: '/home/music', name: 'musics', component: Music },
            { path: '/home/music/:typeid', name: 'music', component: Music },
            { path: '/home/album', name: 'album', component: Album },
            { path: '/home/music/musicDetail/:musicid', name: 'musicDetail', component: MusicDetail },
            { path: '/home/original', name: 'original', component: Original },
            { path: '/home/forum', name: 'forum', component: Forum },
            { path: '/home/contact', name: 'contact', component: Contact }
        ]
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    next();
});

export default router;
