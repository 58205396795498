<template>
    <div class="music-score-wrap">
        <div id="music-score"></div>
        <div id="bar"></div>
    </div>
</template>

<script>
var musicScore = null;
import $ from 'jquery';
import MusicCore from 'music-score-core';
import { music } from './js/data.js';
import { mapState } from 'vuex';
export default {
    name: 'MusicScore',
    data() {
        return {
            musicScore: null,
            isPlay: false
        };
    },
    computed: {
        ...mapState(['playState', 'playOption', 'playInfo'])
    },
    methods: {
        load(data) {
            let musicData = data;
            if (!data) musicData = music();
            musicScore = MusicCore.create(
                {
                    contain: $('#music-score'),
                    tool: $('#tool'),
                    property: $('#property'),
                    bar: $('#bar'),
                    data: musicData
                },
                () => {
                    // loading.close();
                }
            );
            musicScore.bind('playing', this.onPlaying);
            musicScore.bind('played', this.onPlayed);
        },
        // 监听正在播放
        onPlaying(e) {
            if (e.type === 'content') {
                let y = e.$dom.offset().top;
                window.scrollTo(0, y - 150);
            }
            if (e.type === 'countIn') {
                let countIn = this.playOption.countIn;
                this.playState.countIn = countIn - (e.index + 1);
            }
            if (e.type === 'musicScore') {
                this.playState.isCountIn = false;
            }
            this.playState.isPlay = true;
        },
        // 监听播放结束
        onPlayed(e) {
            if (e.type === 'musicScore') {
                this.playState.isPlay = false;
            }
        },
        // 播放
        play(options) {
            if (options) this.setOption(options);
            MusicCore.setSpeed(options.speed);
            this.playState.isCountIn = true;
            this.playState.countIn = options.countIn;
            if (this.playState.isPlay) {
                this.replay(options);
                return;
            }
            musicScore.play({
                from: options.from,
                startIndex: this.playState.startIndex - 1,
                playInfo: this.playInfo,
                countIn: options.countIn,
                soundfontUrl: '/midi-js-soundfonts/'
            });
        },
        // 暂停
        suspend() {
            musicScore.stop();
        },
        // 重播
        replay(options) {
            musicScore.stop();
            if (options) this.setOption(options);
            let timer = setInterval(() => {
                if (!this.playState.isPlay) {
                    this.playState.isCountIn = true;
                    musicScore.play({
                        from: options.from,
                        startIndex: this.playState.startIndex - 1,
                        playInfo: this.playInfo,
                        countIn: options.countIn,
                        soundfontUrl: '/midi-js-soundfonts/'
                    });
                    clearInterval(timer);
                }
            }, 500);
        },
        // 速度
        speed(options) {
            this.playOption.speed = options.speed;
            this.setOption(this.playOption);
            MusicCore.setSpeed(this.playOption.speed);
        },
        // 加载轨道信息
        loadPlayInfo() {
            let playInfo = musicScore.getPlayTrackInfo();
            playInfo.forEach(info => {
                info.tracks.forEach(track => {
                    track.selected = true;
                });
            });
            this.$setState('playInfo', playInfo);
        },
        // 存储配置信息
        setOption(options) {
            this.$setState('playOption', {
                speed: options.speed,
                countIn: options.countIn,
                from: options.from
            });
        }
    },
    mounted() {
        this.playOption.speed = this.playOption.speed || 80;
        this.playOption.countIn = this.playOption.countIn || 0;
    },
    beforeDestroy() {
        musicScore && musicScore.stop();
    }
};
</script>

<style scoped lang="scss">
.music-score-wrap {
    padding: 25px 0;
    border-top: 1px solid #ebedf2;
    border-bottom: 1px solid #ebedf2;
}
</style>
