<template>
    <div class="login-wrap">
        <Header></Header>
        <Navigation></Navigation>
        <div class="main__content">
            <div class="tabs__wrap"><div class="tabs__item tabs__item--active">注册</div></div>
            <!-- 注册 -->
            <div class="content__wrap">
                <div class="input__wrap user">
                    <i class="input__icon user"></i>
                    <div class="input__line"></div>
                    <input class="input" type="text" v-model="userName" placeholder="请输入用户名" />
                </div>
                <div class="input__wrap">
                    <i class="input__icon phone"></i>
                    <div class="input__line"></div>
                    <input class="input" type="text" v-model="mobile" placeholder="请输入手机号" />
                </div>
                <div class="figure__wrap">
                    <div class="input__wrap">
                        <i class="input__icon figure"></i>
                        <div class="input__line"></div>
                        <input class="input" type="text" v-model="captcha" placeholder="请输入图形验证码" />
                    </div>
                    <img class="figure__img" :src="captchaImg" alt="" @click="getCaptcha" />
                </div>
                <div class="input__wrap">
                    <i class="input__icon code"></i>
                    <div class="input__line"></div>
                    <input class="input" type="number" v-model="code" placeholder="请输入短信验证码" />
                    <div class="input__line"></div>
                    <div class="send__button" :class="{ 'send__button--disable': !isSend }" @click="getCode">{{ sendButtonText }}</div>
                </div>
                <div class="input__wrap password">
                    <i class="input__icon password"></i>
                    <div class="input__line"></div>
                    <input class="input" type="password" v-model="password" placeholder="请输入登录密码" />
                </div>
                <div class="input__wrap" :class="{ 'confirm-password': !isEqual }">
                    <i class="input__icon password"></i>
                    <div class="input__line"></div>
                    <input class="input" type="password" v-model="confirmPassword" placeholder="再次输入密码" />
                </div>
                <div class="input__wrap">
                    <i class="input__icon email"></i>
                    <div class="input__line"></div>
                    <input class="input" type="text" v-model="email" placeholder="请输入常用的电子邮箱" />
                </div>
                <div class="user-agreement__wrap" @click="handleChangeAgreement">
                    <i class="check__icon" v-if="!userAgreement"></i>
                    <i class="check__icon check__icon--active" v-else></i>
                    <span class="agreement__label">注册前请仔细阅读</span>
                    <span class="agreement__button" @click="handleLookAgreement">《天音树曲谱网用户协议》</span>
                </div>
                <button class="theme-button register__button" @click="handleRegister">注册</button>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Navigation from '@/components/Navigation';
import { register, captcha, code } from '@/api/user';
export default {
    components: {
        Header,
        Footer,
        Navigation
    },
    data() {
        return {
            userName: '', // 用户名
            account: '', // 账号
            password: '', // 密码
            confirmPassword: '', // 确认密码
            mobile: '', // 手机
            email: '', // 邮箱
            captcha: '', // 图形验证码
            code: '', // 短信验证码
            userAgreement: false, // 用户协议
            captchaKey: '', // 图形验证码Key
            captchaImg: '', // 图形验证码,
            isSend: true, // 是否可以发送短信验证码
            sendButtonText: '发送验证码', // 发送验证码按钮
            timer: null // 计时器
        };
    },
    created() {
        this.getCaptcha();
    },
    computed: {
        // 两次密码是否相同
        isEqual() {
            return this.password == this.confirmPassword;
        }
    },
    methods: {
        // 用户注册
        handleRegister() {
            if (!this.userAgreement) {
                this.$message({
                    message: '请勾选用户协议哦~',
                    type: 'warning'
                });
                return;
            } else if (!this.isEqual) {
                return;
            }
            register({
                mobile: this.mobile,
                code: this.code,
                captcha: this.captcha,
                key: this.captchaKey,
                email: this.email,
                passWord: this.password,
                userName: this.userName,
                type: 'REGISTER'
            }).then(res => {
                if (res.success) {
                    this.$message({
                        message: '用户注册成功',
                        type: 'success'
                    });
                    this.$router.push({ name: 'login' });
                }
            });
        },
        // 获取图形验证码
        getCaptcha() {
            captcha().then(res => {
                this.captchaKey = res.data.key;
                this.captchaImg = res.data.image;
            });
        },
        // 获取短信验证码
        getCode() {
            if (!this.mobile) {
                this.$message({
                    message: '请先填写手机号哦~',
                    type: 'warning'
                });
                return;
            } else if (!this.captcha) {
                this.$message({
                    message: '请先填写图形验证码~',
                    type: 'warning'
                });
                return;
            } else if (this.isSend) {
                code({
                    phone: this.mobile,
                    captcha: this.captcha,
                    key: this.captchaKey,
                    type: 'REGISTER'
                }).then(res => {
                    if (res.success) {
                        let num = 59;
                        this.isSend = false;
                        this.timer = setInterval(() => {
                            num--;
                            this.sendButtonText = `发送中(${num})`;
                            if (num == 0) {
                                clearInterval(this.timer);
                                this.sendButtonText = '发送验证码';
                                this.isSend = true;
                            }
                        }, 1000);
                    }
                });
            }
        },
        // 用户协议选中切换
        handleChangeAgreement() {
            this.userAgreement = !this.userAgreement;
        },
        // 查看用户协议
        handleLookAgreement() {
            this.$router.push({ name: 'agreement' });
        }
    }
};
</script>

<style scoped lang="scss">
.login-wrap {
    display: flex;
    flex-direction: column;
    .main__content {
        width: 1200px;
        margin: 60px auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        .tabs__wrap {
            width: 480px;
            display: flex;
            align-items: center;
            .tabs__item {
                width: 100%;
                height: 50px;
                color: #bfc3cc;
                font-size: 18px;
                cursor: default;
                font-weight: bold;
                transition: all 0.3s;
                border-bottom: 1px solid #e5e5e5;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .tabs__item--active {
                color: #4195ff;
                font-size: 26px;
                border-bottom: 2px solid #3f95ff;
            }
        }
        .content__wrap {
            width: 480px;
            padding-top: 60px;
            .figure__wrap {
                display: flex;
                align-items: flex-start;
                .input__wrap {
                    width: 312px !important;
                }
                .figure__img {
                    width: 150px;
                    height: 60px;
                    margin-left: 20px;
                    border-radius: 12px;
                    display: inline-block;
                }
            }
            .input__wrap {
                width: 100%;
                height: 60px;
                flex-shrink: 0;
                padding: 17px 30px;
                margin-bottom: 30px;
                border-radius: 12px;
                background: #ffffff;
                border: 1px solid #e5e5e4;
                position: relative;
                display: flex;
                align-items: center;
                .input__icon {
                    width: 28px;
                    height: 26px;
                    flex-shrink: 0;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
                .input__line {
                    width: 1px;
                    height: 100%;
                    margin: 0 30px;
                    background-color: #eaedf2;
                }
                .input {
                    width: 100%;
                    border: none;
                    font-size: 18px;
                }
                .input__icon.user {
                    background-size: 19px 20px;
                    background-image: url(../../assets/icon/user.png);
                }
                .input__icon.phone {
                    background-size: 18px 26px;
                    background-image: url(../../assets/icon/phone.png);
                }
                .input__icon.password {
                    background-size: 20px 26px;
                    background-image: url(../../assets/icon/password.png);
                }
                .input__icon.figure {
                    background-size: 24px 24px;
                    background-image: url(../../assets/icon/figure.png);
                }
                .input__icon.code {
                    background-size: 28px 22px;
                    background-image: url(../../assets/icon/code.png);
                }
                .input__icon.email {
                    background-size: 22px 17px;
                    background-image: url(../../assets/icon/email.png);
                }
                .send__button {
                    color: #4195ff;
                    font-size: 18px;
                    cursor: pointer;
                    white-space: nowrap;
                }
                .send__button--disable {
                    color: #c0c4cc;
                }
            }
            .input__wrap.user:after {
                position: absolute;
                content: '可包含中文、字母、数字长度12个字符以内';
                color: #c0c4cc;
                font-size: 16px;
                left: 500px;
                top: 50%;
                white-space: nowrap;
                transform: translateY(-50%);
            }
            .input__wrap.password:after {
                position: absolute;
                content: '密码可使用数字、字母、下划线等，6-30个字符';
                color: #c0c4cc;
                font-size: 16px;
                left: 500px;
                top: 50%;
                white-space: nowrap;
                transform: translateY(-50%);
            }
            .input__wrap.confirm-password:after {
                position: absolute;
                content: '两次输入的密码不相同!';
                color: #f56c6c;
                font-size: 16px;
                left: 500px;
                top: 50%;
                white-space: nowrap;
                transform: translateY(-50%);
            }
            .user-agreement__wrap {
                cursor: default;
                display: flex;
                align-items: center;
                .check__icon {
                    width: 24px;
                    height: 24px;
                    margin-right: 27px;
                    background-repeat: no-repeat;
                    background-size: 24px 24px;
                    background-image: url(../../assets/icon/check.png);
                }
                .check__icon.check__icon--active {
                    background-image: url(../../assets/icon/check-acitve.png);
                }
                .agreement__label {
                    color: #909399;
                    font-size: 18px;
                }
                .agreement__button {
                    color: #4196ff;
                    font-size: 18px;
                    cursor: pointer;
                }
                .agreement__button:hover {
                    text-decoration: underline;
                }
            }
            .register__button {
                width: 100%;
                cursor: pointer;
                margin-top: 60px;
            }
        }
    }
}
</style>
