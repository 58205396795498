<template>
    <div class="music-wrap">
        <p>敬请期待</p>
    </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.music-wrap {
    p {
        font-size: 36px;
        font-weight: bold;
        text-align: center;
    }
}
</style>
