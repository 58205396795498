<template>
    <div class="main-wrap">
        <Header></Header>
        <Navigation></Navigation>
        <router-view></router-view>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Navigation from '@/components/Navigation';
import { getUserInfo } from '@/api/user';
import { mapState } from 'vuex';
export default {
    components: {
        Header,
        Footer,
        Navigation
    },
    data() {
        return {};
    },
    created() {
        this.getUserInfo();
    },
    computed: {
        ...mapState(['token'])
    },
    methods: {
        // 获取用户信息
        getUserInfo() {
            // 权限判断
            if (this.token) {
                getUserInfo().then(res => {
                    if (res.success) {
                        this.$setState('userInfo', res.data.data);
                    }
                });
            }
        }
    }
};
</script>

<style scoped lang="scss">
.main-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
}
</style>
