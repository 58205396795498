<template>
    <div class="modules-wrap">
        <!-- 图片 -->
        <div class="modules__item" :class="moduleData.type" v-if="moduleData.type == 'IMAGE'">
            <img class="modules__img" :src="moduleData.image" alt="" @click="handleClickImg" />
        </div>
        <!-- 曲谱 -->
        <div class="modules__item" :class="moduleData.type" v-if="moduleData.type == 'MUSIC'">
            <div class="modules__music" v-for="(moduleBox, moduleBoxIndex) in moduleData.box" :key="moduleBoxIndex">
                <PlateHeader :title="moduleBox.title" @clickMore="handleClickModuleMore(moduleBox)"></PlateHeader>
                <div class="modules__music__list">
                    <div class="list__item" v-for="(musics, musicsIndex) in moduleBox.musicScoreList" :key="musicsIndex">
                        <div class="list__item__top">
                            <span class="music__tagname">[{{ musics.tagName }}]</span>
                            <span class="music__title">{{ musics.title }}</span>
                        </div>
                        <div class="list__item__bottom">
                            <span>作词：{{ musics.authorWord }}</span>
                            <span>作曲：{{ musics.authorScore }}</span>
                            <span>演唱：{{ musics.authorSing }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 其他类型 -->
        <div class="modules__item" :class="moduleData.type" v-if="moduleData.type == 'test'">
            <!-- 认证作曲人 -->
            <div class="section__right__item">
                <PlateHeader class="authentication" title="认证作曲人" title-size="30" :show-icon="false"></PlateHeader>
                <div class="item__content composer">
                    <div class="content__composer" v-for="(item, index) in 3" :key="index">
                        <img class="avatar" src="https://img2.yuntouxiang.com/file/20170124/20d8c54b8843a37c240fd918a1019bd8.jpg" alt="" />
                        <div class="info-wrap">
                            <div class="name-wrap">
                                <span class="name">林儒生</span>
                                <i class="vip"></i>
                            </div>
                            <div class="works-wrap">
                                <span class="label">代表作</span>
                                <span class="works">《秦淮河边》、《天宫赐福》、《亲爱的祖国我爱你》</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 平台公告 -->
            <div class="section__right__item">
                <div class="item__content notice">
                    <PlateHeader title="平台公告"></PlateHeader>
                    <div class="content__notice" v-for="(item, index) in 6" :key="index">
                        <div class="date">[07-18]</div>
                        <div class="notice">一带一路与爱同行公告信息公告...</div>
                    </div>
                </div>
            </div>
            <!-- 热门话题 -->
            <div class="section__right__item">
                <div class="item__content problem">
                    <PlateHeader title="热门话题"></PlateHeader>
                    <div class="content__problem" v-for="(item, index) in 4" :key="index">
                        <div class="header__wrap">
                            <i class="header__icon"></i>
                            <span class="header__title">求谱区(3)</span>
                        </div>
                        <div class="content__wrap">
                            <div class="header">
                                <div class="flex">
                                    <span class="label">主题：</span>
                                    <span class="count">1450</span>
                                </div>
                                <div class="flex">
                                    <span class="label">帖数：</span>
                                    <span class="count">2503</span>
                                </div>
                            </div>
                            <div class="bottom"><span class="label">最后发表：3小时前</span></div>
                        </div>
                    </div>
                    <div class="all__button">查看全部</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PlateHeader from '@/components/PlateHeader';
export default {
    name: 'Modules',
    components: {
        PlateHeader
    },
    props: {
        moduleData: {
            type: [String, Array, Object]
        }
    },
    data() {
        return {};
    },
    methods: {
        // 点击图片模块
        handleClickImg() {
            console.log('点击图片模块', this.moduleData);
        },
        // 点击模块更多
        handleClickModuleMore(item) {
            console.log('点击模块更多', item);
        }
    }
};
</script>

<style scoped lang="scss">
.modules-wrap {
    width: 100%;
    margin-bottom: 20px;
    // 图片
    .modules__item.IMAGE {
        width: 100%;
        .modules__img {
            width: 100%;
        }
    }
    // 音乐
    .modules__item.MUSIC {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .modules__music {
            width: calc(100% / 2 - 10px);
            border: 1px solid #d8dde6;
            padding: 30px;
            .modules__music__list {
                display: flex;
                flex-direction: column;
                .list__item {
                    margin-top: 30px;
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    .list__item__top {
                        display: flex;
                        align-items: center;
                        .music__tagname {
                            color: #4296ff;
                            flex-shrink: 0;
                            font-size: 16px;
                            margin-right: 10px;
                        }
                        .music__title {
                            color: #303133;
                            font-size: 16px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                    .list__item__bottom {
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        span {
                            color: #909399;
                            font-size: 14px;
                        }
                    }
                }
                .list__item:hover {
                    .music__title {
                        color: #4296ff;
                    }
                }
            }
        }
        .modules__music:nth-child(odd) {
            margin-right: auto;
        }
    }

    // 其他类型
    .section__right__item {
        display: flex;
        flex-direction: column;
        .authentication {
            height: 44px;
        }
        .item__content {
            padding: 0 20px;
            border: 1px solid #d7dce5;
            display: flex;
            flex-direction: column;
        }
        .item__content.composer {
            height: 425px;
            margin-top: 30px;
            margin-bottom: 20px;
            .content__composer {
                padding: 25px 0;
                border-bottom: 1px solid #f4f6f9;
                display: flex;
                align-items: center;
                .avatar {
                    width: 80px;
                    height: 80px;
                    flex-shrink: 0;
                    margin-right: 20px;
                    border-radius: 100%;
                }
                .info-wrap {
                    display: flex;
                    flex-direction: column;
                    .name-wrap {
                        margin-bottom: 10px;
                        display: flex;
                        align-items: center;
                        .name {
                            color: #2f3033;
                            font-size: 20px;
                            font-weight: bold;
                            margin-right: 5px;
                        }
                        .vip {
                            width: 22px;
                            height: 26px;
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                            background-image: url(../../assets/icon/vip.png);
                        }
                    }
                    .works-wrap {
                        display: inline;
                        line-height: 24px;
                        .label {
                            color: #8f9299;
                        }
                        .works {
                            color: #173254;
                        }
                    }
                }
            }
        }
        .item__content.notice {
            margin-bottom: 20px;
            padding: 30px 30px 0 30px;
            .content__notice {
                padding: 20px 0;
                border-bottom: 1px solid #f4f6f9;
                cursor: pointer;
                display: flex;
                align-items: center;
                .date {
                    width: 68px;
                    height: 26px;
                    color: #5f6166;
                    line-height: 26px;
                    margin-right: 20px;
                    text-align: center;
                    background-color: #efefef;
                }
                .notice {
                    color: #2f3033;
                    font-size: 16px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            .content__notice:last-child {
                border-bottom: none;
            }
            .content__notice:hover {
                .date {
                    color: #4195ff;
                    background-color: #ecf4ff;
                }
                .notice {
                    color: #4195ff;
                }
            }
        }
        .item__content.problem {
            padding: 30px 30px 0 30px;
            .content__problem {
                padding: 20px 0;
                border-bottom: 1px solid #f4f6f9;
                display: flex;
                flex-direction: column;
                .header__wrap {
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    .header__icon {
                        width: 40px;
                        height: 40px;
                        margin-right: 20px;
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        background-image: url(../../assets/icon/problem-icon1.png);
                    }
                    .header__title {
                        color: #2f3033;
                        font-size: 20px;
                        font-weight: bold;
                    }
                }
                .content__wrap {
                    padding: 20px;
                    font-size: 16px;
                    margin-left: 60px;
                    background-color: #f4f4f5;
                    .header {
                        margin-bottom: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .flex {
                            display: flex;
                            align-items: center;
                        }
                    }
                    .label {
                        color: #8f9299;
                    }
                    .count {
                        color: #2f3033;
                        font-weight: bold;
                    }
                }
            }
            .all__button {
                width: 110px;
                height: 40px;
                margin: 20px auto;
                color: #4195ff;
                cursor: pointer;
                font-size: 16px;
                border-radius: 12px;
                background: #ffffff;
                border: 1px solid #4195ff;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
</style>
