import request from '@/utils/request';

// banner图
export function banner(data) {
    return request({
        url: '/api/music/banner',
        method: 'get',
        params: data
    });
}

// 曲谱模块数据
export function musicModule(data) {
    return request({
        url: '/api/webModule/data',
        method: 'get',
        params: data
    });
}

// 推荐标签
export function recommendTag(data) {
    return request({
        url: '/api/music/recommendTag',
        method: 'get',
        params: data
    });
}
