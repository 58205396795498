<template>
    <div class="index-wrap">
        <!-- 内容头部 -->
        <div class="content__header">
            <el-carousel :interval="5000" arrow="always">
                <el-carousel-item v-for="item in banners" :key="item.id">
                    <img class="banner__img" :src="item.image" alt="" />
                </el-carousel-item>
            </el-carousel>
            <!-- 暂未开放 -->
            <!-- <div class="header__right">
                <div class="entry-button">论坛入口</div>
                <div class="entry-button">申请认证作曲人</div>
            </div> -->
        </div>
        <!-- 曲谱分类 -->
        <MusicType></MusicType>
        <!-- 底部内容 -->
        <div class="content_module">
            <!-- 左边模块 -->
            <div class="module__left">
                <!-- 热门推荐 -->
                <div class="module__recommend">
                    <h2 class="recommend__title">热门推荐</h2>
                    <span class="recommend__item" v-for="(item, index) in recommendTags" :key="index">{{ item.name }}</span>
                </div>
                <!-- 顶部模块 -->
                <div class="top__module">
                    <PlateHeader :title="topModules.tagName" @clickMore="handleClickMore"></PlateHeader>
                    <div class="module__list">
                        <div class="list__item" v-for="(modules, index) in topModules.musicScoreList" :key="index">
                            <span class="item__type">[{{ modules.tagName }}]</span>
                            <span class="item__name">{{ modules.title }}</span>
                        </div>
                    </div>
                </div>
                <!-- 动态模块 -->
                <Modules :moduleData="item" v-for="(item, index) in centerModules" :key="index"></Modules>
            </div>
            <!-- 右边模块 -->
            <div class="module__right">
                <!-- 动态模块 -->
                <Modules :moduleData="item" v-for="(item, index) in rightModules" :key="index"></Modules>
            </div>
        </div>
    </div>
</template>

<script>
import MusicType from '@/components/MusicType';
import PlateHeader from '@/components/PlateHeader';
import Modules from '@/components/Modules';
import { banner, musicModule, recommendTag } from '@/api/index';
export default {
    components: {
        Modules,
        MusicType,
        PlateHeader
    },
    data() {
        return {
            banners: [], // banner列表
            recommendTags: [], // 热门推荐
            topModules: [], // 顶部模块数据
            centerModules: [], // 中间模块数据
            bottomModules: [] // 底部模块数据
        };
    },
    created() {
        this.getBanner();
        this.getMusicModule();
        this.getRecommendTag();
    },
    methods: {
        // 点击模块更多
        handleClickMore(event) {
            console.log(event);
        },
        // 获取轮播图
        getBanner() {
            banner({ type: 'INDEX' }).then(res => {
                this.banners = res.data.data;
            });
        },
        // 推荐标签数据
        getRecommendTag() {
            recommendTag().then(res => {
                this.recommendTags = res.data.data;
            });
        },
        // 模块数据
        getMusicModule() {
            musicModule({
                pageName: 'INDEX'
            }).then(res => {
                this.topModules = res.data.data.LIST;
                this.centerModules = res.data.data.CENTER;
                this.rightModules = res.data.data.RIGHT;
            });
        }
    }
};
</script>

<style scoped lang="scss">
.index-wrap {
    width: 1200px;
    margin: 0 auto 20px auto;
    display: flex;
    flex-direction: column;
    .content__header {
        margin-top: 20px;
        display: flex;
        .el-carousel {
            border-radius: 12px;
            width: 100%;
            // width: calc(100% - 400px);
        }
        .el-carousel__item .banner__img {
            width: 100%;
            height: 300px;
        }
        .header__right {
            width: 380px;
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .entry-button {
                width: 100%;
                height: 140px;
                border-radius: 12px;
                transition: box-shadow 0.3s;
                cursor: pointer;
                background-color: #4196ff;
            }
            .entry-button:hover {
                box-shadow: 0 5px 5px #e5e5e5;
            }
        }
    }

    .content_module {
        display: flex;
        justify-content: space-between;
        .module__left {
            width: calc(100% - 400px);
            display: flex;
            flex-direction: column;
            .module__recommend {
                margin-bottom: 30px;
                display: flex;
                align-items: center;
                .recommend__title {
                    flex-shrink: 0;
                    font-size: 30px;
                    font-weight: bold;
                    margin: 0 40px 0 0;
                }
                .recommend__item {
                    width: 120px;
                    height: 44px;
                    color: #606266;
                    font-size: 18px;
                    border-radius: 12px;
                    background-color: #efefef;
                    transition: all 0.3s;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .recommend__item:not(:last-child) {
                    margin-right: 20px;
                }
                .recommend__item:hover {
                    color: #ffffff;
                    background-color: #4196ff;
                }
                .recommend__item.recommend__item--active {
                    color: #ffffff;
                    background-color: #4196ff;
                }
            }
            .top__module {
                padding: 30px;
                margin-bottom: 20px;
                border: 1px solid #d8dde6;
                display: flex;
                flex-direction: column;
                .module__list {
                    margin-top: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    .list__item {
                        width: 50%;
                        padding: 10px 0;
                        margin-top: 10px;
                        cursor: pointer;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        .item__type {
                            color: #4196ff;
                            margin-right: 10px;
                        }
                    }
                    .list__item:nth-child(2n) {
                        padding-left: 10px;
                    }
                    .list__item:hover .item__name {
                        color: #4196ff;
                    }
                }
            }
        }
        .module__right {
            width: 380px;
            display: flex;
            flex-direction: column;
        }
    }
}
</style>
