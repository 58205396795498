import request from '@/utils/request';

//密码登录
export function login(data) {
    return request({
        url: '/auth/ms/ms-login',
        method: 'get',
        params: data
    });
}

//验证码登录
export function codeLogin(data) {
    return request({
        url: '/auth/ms/ms-sms-login',
        method: 'get',
        params: data
    });
}

//用户注册
export function register(data) {
    return request({
        url: '/auth/ms/ms-register',
        method: 'post',
        data
    });
}

//获取图形验证码
export function captcha(data) {
    return request({
        url: '/auth/ms/captcha',
        method: 'get',
        params: data
    });
}

//获取短信验证码
export function code(data) {
    return request({
        url: '/api/biz/sms/phone',
        method: 'post',
        data
    });
}

// 获取用户信息
export function getUserInfo(data) {
    return request({
        url: '/api/core/user/userInfo',
        method: 'get',
        params: data
    });
}

// 获取用户协议
export function getAgreement(data) {
    return request({
        url: '/api/music/config',
        method: 'get',
        params: data
    });
}

// 退出登录
export function logout(data) {
    return request({
        url: '/auth/logout',
        method: 'get',
        params: data
    });
}
