import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import ElementUI from 'element-ui';
import './registerServiceWorker';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/reset.scss';
import '@/styles/common.scss';
import '@/styles/elementStyle.scss';
import 'music-score-core/dist/music-score-core.css';

import utils from './utils';
import filters from './filters';
import mixin from './mixins/mixin.js';

Vue.use(ElementUI);

// 混入全局Mixin
Vue.mixin(mixin);

// 挂载所有工具方法到Vue实例
Object.keys(utils).forEach(key => {
    Vue.prototype[`$${key}`] = utils[key];
});

// 注册全局过滤器
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
