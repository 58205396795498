<template>
    <div class="music-wrap">
        <!-- 曲谱分类 -->
        <MusicType></MusicType>
        <div class="content__wrap">
            <div class="left__wrap">
                <!-- 热门专辑 -->
                <div class="item__wrap">
                    <PlateHeader title="推荐分类" :showMore="false"></PlateHeader>
                    <div class="recommend__item" v-for="(item, index) in recommends" :key="index" @click="handleClickRecommend(item)">
                        <i class="recommend__ranking">{{ index + 1 }}</i>
                        <span class="recommend__name" :title="item.name">{{ item.name }}</span>
                    </div>
                </div>
                <!-- 推荐曲谱 -->
                <div class="item__wrap">
                    <PlateHeader title="推荐曲谱" :showMore="false"></PlateHeader>
                    <div class="music__item" v-for="(item, index) in 5" :key="index" @click="handleClickMusic(item)">
                        <span class="music__type">原创曲谱</span>
                        <span class="music__name">三门峡畅想曲（钢琴独奏曲</span>
                    </div>
                </div>
                <!-- 热门曲谱 -->
                <div class="item__wrap">
                    <PlateHeader title="热门曲谱" :showMore="false"></PlateHeader>
                    <div class="music__item" v-for="(item, index) in 5" :key="index">
                        <span class="music__type">原创曲谱</span>
                        <span class="music__name">三门峡畅想曲（钢琴独奏曲</span>
                    </div>
                </div>
            </div>
            <div class="right__wrap">
                <!-- 轮播图 -->
                <el-carousel :interval="5000" arrow="always">
                    <el-carousel-item v-for="item in banners" :key="item.id">
                        <img class="banner__img" :src="item.image" alt="" />
                    </el-carousel-item>
                </el-carousel>
                <!-- 搜索内容 -->
                <div class="search__wrap">
                    <el-dropdown trigger="click">
                        <span class="search__type">
                            {{ searchTypeTitle }}
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(item, index) in 1" :key="index">所有分类</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <input class="search__input" type="text" v-model="searchTitle" placeholder="请输入搜索内容" @keyup.enter="handleSearchMusic" />
                    <i class="search__icon" @click="handleSearchMusic"></i>
                </div>
                <!-- 曲谱列表 -->
                <div class="music__wrap">
                    <div class="music__header">
                        <div class="header__label header__label--title">曲谱标题</div>
                        <div class="header__label header__label--author">词/曲作者</div>
                        <div class="header__label header__label--singing">演唱(者)</div>
                        <div class="header__label header__label--date">上传时间</div>
                    </div>
                    <div class="music__item" v-for="(item, index) in musics" :key="index" @click="handleClickMusic(item)">
                        <div class="item__title">
                            <span class="title__index">{{ pageSize * (pageNumber - 1) + (index + 1) }}.</span>
                            <span class="title">{{ item.title }}</span>
                        </div>
                        <div class="item__author">{{ item.authorWord }}/{{ item.authorScore }}</div>
                        <div class="item__singing">{{ item.authorSing }}</div>
                        <div class="item__date">{{ item.createDate }}</div>
                    </div>
                </div>
                <!-- 分页 -->
                <el-pagination background layout="prev, pager, next" :total="total" :current-page="pageNumber" @current-change="handlePageChange"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import MusicType from '@/components/MusicType';
import PlateHeader from '@/components/PlateHeader';
import { musics, recommend } from '@/api/music';
import { banner } from '@/api/index';
export default {
    components: {
        MusicType,
        PlateHeader
    },
    data() {
        return {
            searchTitle: '', // 搜索名
            searchTypeTitle: '所有类别', // 搜索类别名
            musicTypeId: '', // 曲谱分类ID
            musics: '', // 曲谱列表
            banners: [], // banner列表
            recommends: [], // 推荐分类
            albumList: ['最新曲谱', '原创曲谱', '最热曲谱', '最新曲谱'], // 热门推荐,
            pageSize: 10,
            pageNumber: 1,
            total: 0
        };
    },
    watch: {
        $route(val) {
            this.musicTypeId = val.params.typeid;
            this.pageNumber = 1;
            this.getMusics();
        }
    },
    created() {
        this.getMusics();
        this.getBanner();
        this.getRecommend();
    },
    methods: {
        // 搜索曲谱
        handleSearchMusic() {
            musics({
                title: this.searchTitle,
                pageSize: this.pageSize,
                pageNumber: this.pageNumber
            }).then(res => {
                this.total = Number(res.data.total);
                this.musics = res.data.list;
            });
        },
        // 获取曲谱分类下的列表
        getMusics() {
            musics({
                title: this.searchTitle,
                categoryList: this.$route.params.typeid == '-1' ? '' : [this.$route.params.typeid],
                pageSize: this.pageSize,
                pageNumber: this.pageNumber
            }).then(res => {
                this.total = Number(res.data.total);
                this.musics = res.data.list;
            });
        },
        // 获取轮播图
        getBanner() {
            banner({ type: 'INDEX' }).then(res => {
                this.banners = res.data.data;
            });
        },
        // 获取热门专辑
        getRecommend() {
            recommend().then(res => {
                this.recommends = res.data.data;
            });
        },
        // 分页
        handlePageChange(page) {
            this.pageNumber = page;
            this.getMusics();
        },
        // 点击推荐分类
        handleClickRecommend(item) {
            this.$router.push({ path: `/home/music/${item.id}` });
        },
        // 查看曲谱详情
        handleClickMusic(item) {
            this.$router.push({ path: `/home/music/musicDetail/${item.id}` });
        }
    }
};
</script>

<style scoped lang="scss">
.music-wrap {
    width: 1200px;
    margin: 0 auto 20px auto;
    .content__wrap {
        display: flex;
        .left__wrap {
            width: 380px;
            margin-right: 20px;
            display: flex;
            flex-direction: column;
            .item__wrap {
                padding: 30px;
                margin-bottom: 20px;
                border: 1px solid #d7dce5;
                display: flex;
                flex-wrap: wrap;
                .recommend__item {
                    height: 44px;
                    width: 145px;
                    padding: 0 20px;
                    margin-top: 30px;
                    border-radius: 12px;
                    border: 1px solid #ebeef3;
                    display: flex;
                    cursor: pointer;
                    align-items: center;
                    .recommend__ranking {
                        width: 22px;
                        height: 24px;
                        color: #ffffff;
                        font-size: 20px;
                        margin-right: 20px;
                        font-style: normal;
                        border-radius: 4px;
                        padding-bottom: 5px;
                        position: relative;
                        display: flex;
                        flex-shrink: 0;
                        align-items: center;
                        justify-content: center;
                    }
                    .recommend__name {
                        color: #2f3033;
                        font-size: 18px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    &:nth-child(2) .recommend__ranking {
                        background-color: #ff8116;
                    }
                    &:nth-child(3) .recommend__ranking {
                        background-color: #ff4184;
                    }
                    &:nth-child(4) .recommend__ranking {
                        background-color: #4196ff;
                    }
                    &:nth-of-type(n + 5) .recommend__ranking {
                        background-color: #c0c4cc;
                    }
                    .recommend__ranking:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 0;
                        height: 0;
                        border-left: 11px solid transparent;
                        border-right: 11px solid transparent;
                        border-bottom: 4px solid #ffffff;
                    }
                }
                .recommend__item:nth-child(odd) {
                    margin-left: auto;
                }
                .music__item {
                    cursor: pointer;
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    .music__type {
                        color: #4195ff;
                        flex-shrink: 0;
                        font-size: 16px;
                        margin-right: 10px;
                    }
                    .music__name {
                        color: #2f3033;
                        font-size: 16px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
                .music__item:hover {
                    .music__name {
                        color: #4195ff;
                    }
                }
            }
        }
        .right__wrap {
            width: calc(100% - 400px);
            display: flex;
            flex-direction: column;
            ::v-deep .el-carousel__container {
                height: 210px;
            }
            .el-carousel__item .banner__img {
                width: 100%;
                height: 300px;
            }
            .search__wrap {
                width: 320px;
                height: 40px;
                margin: 40px 0;
                padding: 0 20px;
                display: flex;
                margin-left: auto;
                align-items: center;
                border-radius: 12px;
                border: 1px solid #d7dce5;
                .search__type {
                    flex-shrink: 0;
                    cursor: default;
                    white-space: nowrap;
                }
                .search__input {
                    width: 100%;
                    border: none;
                    margin: 0 10px;
                    font-size: 14px;
                }
                .search__icon {
                    width: 18px;
                    height: 18px;
                    flex-shrink: 0;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-image: url(../../assets/icon/search-blue.png);
                }
            }
            .music__wrap {
                margin-bottom: 30px;
                display: flex;
                flex-direction: column;
                .music__header {
                    height: 55px;
                    border-radius: 12px;
                    background-color: #f4f4f5;
                    display: flex;
                    align-items: center;
                    .header__label {
                        color: #2f3033;
                        flex-shrink: 0;
                        font-size: 16px;
                        font-weight: bold;
                    }
                    .header__label--title {
                        width: 280px;
                        padding-left: 60px;
                    }
                    .header__label--author {
                        width: 170px;
                        text-align: center;
                    }
                    .header__label--singing {
                        width: 170px;
                        text-align: center;
                    }
                    .header__label--date {
                        flex: 1;
                        text-align: right;
                        padding-right: 60px;
                    }
                }
                .music__item {
                    height: 55px;
                    margin-top: 10px;
                    border-radius: 12px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    .item__title {
                        width: 280px;
                        padding-left: 20px;
                        display: flex;
                        align-items: center;
                        .title__index {
                            width: 40px;
                            color: #3f95ff;
                            flex-shrink: 0;
                            font-size: 20px;
                            text-align: left;
                        }
                        .title {
                            color: #2f3033;
                            font-size: 16px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                    .item__author,
                    .item__singing {
                        width: 170px;
                        color: #8f9299;
                        flex-shrink: 0;
                        font-size: 16px;
                        text-align: center;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .item__date {
                        flex: 1;
                        color: #8f9299;
                        flex-shrink: 0;
                        font-size: 16px;
                        padding-right: 20px;
                        text-align: right;
                        white-space: nowrap;
                    }
                }
                .music__item--active {
                    background-color: #f4f4f5;
                }
                .music__item:hover {
                    background-color: #f4f4f5;
                }
            }
            ::v-deep .el-pagination {
                text-align: center;
            }
        }
    }
}
</style>
