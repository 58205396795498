<template>
    <div class="navigation-wrap">
        <div class="navigation__content">
            <router-link class="content-item" :to="item.path" active-class="content-item--active" v-for="(item, index) in list" :key="index">
                {{ item.title }}
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Navigation',
    data() {
        return {
            list: [
                { title: '首页', path: '/home/index' },
                { title: '曲谱', path: '/home/music' },
                { title: '专辑', path: '/home/album' },
                { title: '原创中心', path: '/home/original' },
                { title: '谱曲论坛', path: '/home/forum' },
                { title: '联系我们', path: '/home/contact' }
            ]
        };
    },
    methods: {}
};
</script>

<style scoped lang="scss">
.navigation-wrap {
    width: 100%;
    height: 60px;
    background-color: #4296ff;
    .navigation__content {
        height: 100%;
        width: 1200px;
        margin: 0 auto;
        font-size: 18px;
        display: flex;
        align-items: center;
        .content-item {
            width: 180px;
            height: 100%;
            color: #ffffff;
            display: flex;
            cursor: default;
            align-items: center;
            text-decoration: none;
            justify-content: center;
            transition: background-color 0.3s;
        }
        .content-item.content-item--active {
            background-color: #1f7bec;
        }
        .content-item:hover {
            cursor: pointer;
            background-color: #1f7bec;
        }
    }
}
</style>
